export const getDomainUrl = (subdomain) => {
    const isLocalDev = window.location.hostname === 'localhost';
    if (isLocalDev && (subdomain === 'spacecockpit' || subdomain === 'probe')) {
        return `https://${subdomain}.saberdevusa.xyz`;
    }
    if (isLocalDev && subdomain === 'spacefana') {
        return `http://localhost:3000`;
    }
    return window.location.origin.replace("sbms", subdomain);
};

export const plural = (word, count) => count === 1 ? word : `${word}s`;