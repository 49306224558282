import React, { useContext, useEffect, useState, useCallback } from "react";
import { AppState, getType, getCardColor } from "../lib/context/AppProvider";
import { Table, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "../styles/page.css";
import { FiAlertTriangle } from "react-icons/fi";

function Home() {
    const { postData, getData, user  } = useContext(AppState);
    const [recentNotsos, setRecentNotsos] = useState([]);
    const [recentLaunches, setRecentLaunches] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [blueConstellations, setBlueConstellations] = useState({});
    const [redConstellations, setRedConstellations] = useState({});
    const [blueAlerts, setBlueAlerts] = useState([]);
    const [redAlerts, setRedAlerts] = useState([]);
    const [showAcknowledged, setShowAcknowledged] = useState(false);
    const navigate = useNavigate();
    const [loadingNotso, setLoadingNotso] = useState(false);
    const [loadingAlerts, setLoadingAlerts] = useState(false);
    const lookbackDays = 7;
    
    // Function to get timestamps for alerts query
    const getAlertTimeRange = () => {
        const now = new Date();
        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - lookbackDays);
        
        return {
            start_timestamp: sevenDaysAgo.getTime(),
            end_timestamp: now.getTime()
        };
    };

    // Get user details for iframe token and constellations
    useEffect(() => {
        const fetchUserDetails = async () => {
            if (user.isGuest) return;

            const details = await getData("user-details");
            setUserDetails(details);
            
            if (details?.Constellations) {
                setBlueConstellations(details.Constellations.blues || {});
                setRedConstellations(details.Constellations.reds || {});
            }
        };

        fetchUserDetails();
    }, [getData, user.isGuest]);

    const fetchTeamAlerts = useCallback(async (satellites, timeRange) => {
        if (!satellites.length) return [];
        
        const params = {
            satnos: satellites.join(','),
            start_timestamp: timeRange.start_timestamp.toString(),
            end_timestamp: timeRange.end_timestamp.toString(),
            show_acknowledged: showAcknowledged ? 'true' : 'false'
        };

        return getData('alerts', params);
    }, [getData, showAcknowledged]);

    // Fetch alerts when constellations are loaded or showAcknowledged changes
    useEffect(() => {
        const fetchAllAlerts = async () => {
            if (!userDetails) return;
            
            setLoadingAlerts(true);

            try {
                const blueSats = Object.values(blueConstellations).flat();
                const redSats = Object.values(redConstellations).flat();
                const timeRange = getAlertTimeRange();

                const [blueAlertsData, redAlertsData] = await Promise.all([
                    fetchTeamAlerts(blueSats, timeRange),
                    fetchTeamAlerts(redSats, timeRange)
                ]);

                setBlueAlerts(blueAlertsData || []);
                setRedAlerts(redAlertsData || []);
            } catch (error) {
                console.error('Error fetching alerts:', error);
                setBlueAlerts([]);
                setRedAlerts([]);
            } finally {
                setLoadingAlerts(false);
            }
        };

        fetchAllAlerts();
    }, [blueConstellations, redConstellations, userDetails, getData, showAcknowledged, fetchTeamAlerts]);

    // Fetch recent NOTSOs
    useEffect(() => {
        const fetchRecentNotsos = async () => {
            if (user.isGuest) {
                setRecentLaunches([]);
                setRecentNotsos([]);
                setLoadingNotso(false);
                return;
            }

            setLoadingNotso(true);

            try {
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - lookbackDays);
                const formattedDate = `${sevenDaysAgo.toISOString().split('T')[0]} 00:00:00`;

                const payload = {
                    table: "Notsos",
                    columns: ["*"],
                    wheres: [{
                        column: "CreatedAt",
                        operator: ">",
                        value: formattedDate
                    }],
                    orderBy: [{
                        column: "CreatedAt",
                        direction: "DESC"
                    }]
                };

                const response = await postData("read", payload);
                
                if (response?.status === 200) {
                    const reader = response.body.getReader();
                    const textDecoder = new TextDecoder();
                    let result = '';
                    
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        result += textDecoder.decode(value);
                    }
                    
                    const data = JSON.parse(result);
                    const launches = data.filter(notso => 
                        notso.EventClass.toLowerCase().includes('launch')
                    );
                    const otherNotsos = data.filter(notso => 
                        !notso.EventClass.toLowerCase().includes('launch')
                    );
                    
                    setRecentLaunches(launches);
                    setRecentNotsos(otherNotsos);
                }
            } catch (error) {
                console.error("Error fetching recent NOTSOs:", error);
            } finally {
                setLoadingNotso(false);
            }
        };

        fetchRecentNotsos();
    }, [postData, user.isGuest, lookbackDays]);

    const isLocalDev = window.location.hostname === 'localhost';
    
    const getDomainUrl = (subdomain) => {
        if (isLocalDev && subdomain === 'spacefana') {
            return `http://localhost:3000`;
        }
        return window.location.origin.replace("sbms", subdomain);
    };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto 1fr',
        gap: '1rem',
        padding: '1rem',
        height: 'calc(100vh - 80px)',
        maxHeight: 'calc(100vh - 80px)'
    };

    const tableStyle = {
        backgroundColor: '#1a1d20',
        borderRadius: '8px',
        padding: '1rem',
        height: 'fit-content',
        minHeight: '300px'
    };

    const weatherContainerStyle = {
        gridColumn: '1 / -1',
        backgroundColor: '#1a1d20',
        borderRadius: '8px',
        padding: '1rem',
        height: '100%',
        minHeight: '200px'
    };

    const iframeStyle = {
        width: '100%',
        height: '100%',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#212529'
    };

    const getRelativeTime = (dateString) => {
        const date = new Date(dateString.endsWith("Z") ? dateString : (dateString+"Z"));
        const now = new Date();
        const diffMs = now - date;
        const diffSecs = Math.floor(diffMs / 1000);
        const diffMins = Math.floor(diffSecs / 60);
        const diffHours = Math.floor(diffMins / 60);
        const diffDays = Math.floor(diffHours / 24);

        const remainingHours = diffHours % 24;
        const remainingMins = diffMins % 60;

        let result = '';
        if (diffDays > 0) result += `${diffDays}d `;
        if (remainingHours > 0) result += `${remainingHours}h `;
        if (remainingMins > 0) result += `${remainingMins}m`;
        
        return result ? `${result} ago` : 'just now';
    };

    const tableContainerStyle = {
        maxHeight: '200px',
        overflowY: 'auto',
        minHeight: '200px'
    };

    // Update the constellation alerts tables
    const renderAlertTable = (alerts, loading) => {
        if (loading) {
            return (
                <div className="text-center p-4 d-flex align-items-center justify-content-center" style={{ height: '152px' }}>
                    <div>
                        <div className="spinner-border spinner-border-sm me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        Loading alerts...
                    </div>
                </div>
            );
        }

        if (alerts.length === 0) {
            return (
                <div className="text-center p-4 d-flex align-items-center justify-content-center" style={{ height: '152px' }}>
                    No alerts
                </div>
            );
        }

        return (
            <Table variant="dark" hover>
                <thead>
                    <tr>
                        <th>Created</th>
                        <th>Satellite</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {alerts.map((alert) => {
                        const alertType = alert.UniqueId ? alert.UniqueId.split('-')[2] : '';
                        const type = getType(alertType);
                        const color = getCardColor(type);
                        const rowStyle = {
                            borderLeft: `4px solid var(--bs-${color})`,
                            backgroundColor: 'transparent'
                        };
                        return (
                            <tr key={`${alert.PrimarySatNo}-${alert.CreatedAt}`} style={rowStyle}>
                                <td>{getRelativeTime(alert.CreatedAt)}</td>
                                <td>{`${alert.PrimarySatNo}-${alert.PrimarySatName}`}</td>
                                <td>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>{alert.Description}
                                            <Badge 
                                                bg={alert.Level.toLowerCase()}
                                                className={alert.Level.toLowerCase() === 'warning' ? 'text-dark ms-2' : 'ms-2'}
                                            >
                                                {alert.Level.toUpperCase()}
                                            </Badge>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <Button 
                                        variant="primary" 
                                        size="sm"
                                        onClick={() => navigate(`/alert/${alert.UniqueId}`)}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    return (  
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <div id="full-page-background" className="min-h-screen">
                <div style={gridContainerStyle}>
                    {/* Recent NOTSOs Table - Top Left */}
                    <div style={tableStyle}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4 className="text-xl font-bold mb-0">
                                NOTSOs &nbsp; 
                                <small style={{fontSize: '0.7rem'}}>(Prev {lookbackDays} days) - &nbsp;
                                    {recentNotsos.length > 0 && (<span>{recentNotsos.length} events</span>)}
                                </small> 
                            </h4>
                            <div className="form-check form-switch d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showAcknowledgedSwitch"
                                    checked={showAcknowledged}
                                    onChange={(e) => setShowAcknowledged(e.target.checked)}
                                    disabled={loadingAlerts}
                                />
                                <label className="form-check-label text-white ms-2 d-flex align-items-center gap-2" htmlFor="showAcknowledgedSwitch">
                                    Show Acknowledged
                                    {loadingAlerts && (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                </label>
                            </div>
                        </div>
                        
                        <div style={tableContainerStyle}>
                            <Table variant="dark" hover>
                                <thead>
                                    <tr>
                                        <th>Created</th>
                                        <th>Event Class</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recentNotsos.length > 0 ? (
                                        recentNotsos.map((notso) => {
                                            const type = getType(notso.EventClass);
                                            const color = getCardColor(type);
                                            const rowStyle = {
                                                borderLeft: `4px solid var(--bs-${color})`,
                                                backgroundColor: 'transparent'
                                            };
                                            return (
                                                <tr key={notso.Id} style={rowStyle}>
                                                    <td>{getRelativeTime(notso.CreatedAt)}</td>
                                                    <td>{notso.EventClass}</td>
                                                    <td>
                                                        <Button 
                                                            variant="primary" 
                                                            size="sm"
                                                            onClick={() => navigate(`/event/${notso.IdUdl}`)}
                                                        >
                                                            View
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">{loadingNotso ? "Loading NOTSOs..." : "No recent NOTSOs"}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    {/* Recent Launches - Top Right */}
                    <div style={tableStyle}>
                        <h4 className="text-xl font-bold mb-4">
                            Recent Launches &nbsp; 
                            <small style={{fontSize: '0.7rem'}}>(Prev {lookbackDays} days) - &nbsp;
                                {recentLaunches.length > 0 && (<span>{recentLaunches.length} events</span>)}
                            </small>
                        </h4>
                        
                        <div style={tableContainerStyle}>
                            <Table variant="dark" hover>
                                <thead>
                                    <tr>
                                        <th>Created</th>
                                        <th>Event Class</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recentLaunches.length > 0 ? (
                                        recentLaunches.map((launch) => {
                                            const type = getType(launch.EventClass);
                                            const color = getCardColor(type);
                                            const rowStyle = {
                                                borderLeft: `4px solid var(--bs-${color})`,
                                                backgroundColor: 'transparent'
                                            };
                                            return (
                                                <tr key={launch.Id} style={rowStyle}>
                                                    <td>{getRelativeTime(launch.CreatedAt)}</td>
                                                    <td>{launch.EventClass}</td>
                                                    <td>
                                                        <Button 
                                                            variant="primary" 
                                                            size="sm"
                                                            onClick={() => navigate(`/event/${launch.IdUdl}`)}
                                                        >
                                                            View
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">{loadingNotso ? "Loading launches..." : "No recent launches"}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    {/* BLUE Constellation section */}
                    <div 
                        role="region" 
                        aria-label="blue constellation alerts"
                        style={tableStyle}
                    >
                        <h4 className="text-xl font-bold mb-4">
                            <FiAlertTriangle className="text-primary"/> &nbsp;
                            <span className="text-primary">Blue</span> Team Alerts &nbsp; 
                            <small style={{fontSize: '0.7rem'}}>(Prev {lookbackDays} days) - &nbsp;
                                <span>{blueAlerts.length} events</span>
                            </small> 
                        </h4>
                        <div style={tableContainerStyle}>
                            {Object.keys(blueConstellations).length > 0 ? (
                                renderAlertTable(blueAlerts, loadingAlerts)
                            ) : (
                                <div className="text-center p-4">
                                    <p className="mb-2">No blue constellations defined</p>
                                    <Button 
                                        variant="outline-primary" 
                                        size="sm"
                                        onClick={() => navigate('/settings#constellations')}
                                    >
                                        Add Blue Constellation
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* RED Constellation section */}
                    <div 
                        role="region" 
                        aria-label="red constellation alerts"
                        style={tableStyle}
                    >
                        <h4 className="text-xl font-bold mb-4">
                            <FiAlertTriangle className="text-danger"/> &nbsp;
                            <span className="text-danger">Red</span> Team Alerts &nbsp;
                            <small style={{fontSize: '0.7rem'}}>(Prev {lookbackDays} days) - &nbsp;
                                <span>{redAlerts.length} events</span>
                            </small> 
                        </h4>
                        
                        <div style={tableContainerStyle}>
                            {Object.keys(redConstellations).length > 0 ? (
                                renderAlertTable(redAlerts, loadingAlerts)
                            ) : (
                                <div className="text-center p-4">
                                    <p className="mb-2">No red constellations defined</p>
                                    <Button 
                                        variant="outline-danger" 
                                        size="sm"
                                        onClick={() => navigate('/settings#constellations')}
                                    >
                                        Add Red Constellation
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                
                    {/* Space Weather Graph */}
                    <div style={weatherContainerStyle} data-testid="space-weather-container">
                        {user.isGuest ? (
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div className="text-center">
                                    <h4 className="text-white-50 mb-3">Space Weather Preview</h4>
                                    <p className="text-white-50">Login to view real-time space weather data</p>
                                </div>
                            </div>
                        ) : (
                            <iframe
                                src={`${getDomainUrl('spacefana')}/d/rC4UUfW4d/space-weather?var-accesstoken=${userDetails?.AccessToken}&kiosk`}
                                style={iframeStyle}
                                title="Space Weather"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
