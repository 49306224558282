import React, { useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import {AppState} from "../lib/context/AppProvider";
import { useNavigate } from 'react-router-dom';
import { CiLogout } from "react-icons/ci";
import { GiRadarDish } from "react-icons/gi";
import { FaUser, FaChartBar, FaGlobeAmericas, FaCommentDots, FaFolderOpen, FaCog, FaHome, FaPuzzlePiece   } from "react-icons/fa";
import "../styles/SideBar.css";
import { logoutUser } from "../pages/Account";

const openLink = (path) => {
    // Validate or sanitize the path to prevent XSS
    const sanitizedPath = encodeURI(path); // Example of simple sanitization

    // Ensure the path is a relative path to prevent open redirects
    if (!sanitizedPath.startsWith('/')) {
        console.error('Invalid path');
        return;
    }

    const url = new URL(window.location.origin + sanitizedPath);
    window.open(url.toString(), '_blank', 'noopener,noreferrer')?.focus();
};


const SideBar = ({ visible, handleClose, spaceCockpitLogo }) => {
    const {user, setUser, getBaseUrl } = useContext(AppState);
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        handleClose();
    };

    return (
        <Offcanvas 
            className="white sidebar bg-dark white w-800" 
            show={visible} 
            onHide={handleClose} 
            placement="end"
            data-testid="offcanvas-backdrop"
        >
            <Offcanvas.Body>
                <section className="mt-3 text-center">
                    <img 
                        className="login-logo" 
                        src={spaceCockpitLogo} 
                        alt="logo" 
                        height={100}
                        loading="eager"
                        style={{ aspectRatio: 'auto' }}
                    />
                    <h4 className="text-white">Space Cockpit <br/>Battle Management System</h4>
                </section>
                
                <div className="settings-subsection mt-4">
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation('/home')}
                        onContextMenu={() => openLink('/home')}
                    >
                        <span className="icon"><FaHome /></span> Home
                    </Button>
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation('/spacecockpit')}
                        onContextMenu={() => openLink('/spacecockpit')}
                    >
                        <span className="icon"><FaGlobeAmericas /></span> 3D Space Cockpit
                    </Button>
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation('/dashboards/gwas')}
                        onContextMenu={() => openLink('/dashboards/gwas')}
                    >
                        <span className="icon"><FaChartBar /></span> 2D Analysis & Reports
                    </Button>
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation('/sam')}
                        onContextMenu={() => openLink('/sam')}
                    >
                        <span className="icon"><FaPuzzlePiece  /></span> Space App Marketplace
                    </Button>
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation(`/c2`)}
                        onContextMenu={() => openLink(`/c2`)}
                    >
                        <span className="icon"><GiRadarDish /></span> Command & Control
                    </Button>
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation(`/pair`)}
                        onContextMenu={() => openLink(`/pair`)}
                    >
                        <span className="icon"><FaFolderOpen  /></span> PROBE Intel Dossiers
                    </Button>
                    <Button
                        variant="link"
                        className="sidebar-link w-100 mb-2"
                        onClick={() => handleNavigation('/chat')}
                        onContextMenu={() => openLink('/chat')}
                    >
                        <span className="icon"><FaCommentDots /></span> Chat Ops
                    </Button>
                    {!user.isGuest && (<>
                        <Button
                            variant="link"
                            className="sidebar-link w-100 mb-2"
                            onClick={() => handleNavigation('/settings')}
                            onContextMenu={() => openLink('/settings')}
                        >
                            <span className="icon"><FaCog /></span> Settings
                        </Button>
                        <Button
                            variant="link"
                            className="sidebar-link w-100 mb-2"
                            onClick={() => handleNavigation('/account')}
                            onContextMenu={() => openLink('/account')}
                        >
                            <span className="icon"><FaUser /></span> Account
                        </Button>
                    </>)}
                </div>

                <div className="settings-subsection mt-auto">
                    <Button
                        variant="link"
                        key="logout"
                        className="sidebar-link w-100 mb-4"
                        onClick={() => logoutUser(user, getBaseUrl, navigate, setUser)} 
                    >
                        <span className="icon"><CiLogout /></span> Logout
                    </Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default SideBar;