import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const LicenseAgreementModal = ({ show, onHide, onAccept, appTitle }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        >
            <Modal.Header className="bg-dark text-white border-0">
                <Modal.Title>License Agreement</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                <h5>Space Application Marketplace End User License Agreement</h5>
                <div 
                    style={{ maxHeight: '400px', overflowY: 'auto' }}
                    data-testid="license-scroll-container"
                >
                    <p>
                        Apps made available through the Space Application Marketplace (SAM) are licensed, not sold,
                        to you. Your license to each App is subject to your prior acceptance of either this Licensed
                        Application End User License Agreement ("Standard EULA"), or a custom end user license
                        agreement between you and the Application Provider ("Custom EULA"), if one is provided. Your
                        license to any Saber Astronautics App under this Standard EULA or Custom EULA is granted by
                        Saber Astronautics, and your license to any Third Party App under this Standard EULA or
                        Custom EULA is granted by the Application Provider of that Third Party App. Any App that is
                        subject to this Standard EULA is referred to herein as the "Licensed Application." The Application
                        Provider or Saber Astronautics as applicable ("Licensor") reserves all rights in and to the
                        Licensed Application not expressly granted to you under this Standard EULA.
                    </p>
                    <p>
                        a. Scope of License: Licensor grants to you a nontransferable license to use the Licensed 
                        Application. The terms of this Standard EULA will govern any content, materials, or services 
                        accessible from or purchased within the Licensed Application as well as upgrades provided by 
                        Licensor that replace or supplement the original Licensed Application, unless such upgrade is 
                        accompanied by a Custom EULA. You may not transfer, redistribute or sublicense the Licensed 
                        Application. You may not copy (except as permitted by this license), reverse-engineer, 
                        disassemble, attempt to derive the source code of, modify, or create derivative works of the 
                        Licensed Application, any updates, or any part thereof (except as and only to the extent that any 
                        foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the 
                        licensing terms governing use of any open-sourced components included with the Licensed 
                        Application).
                    </p>
                    <p>
                        b. Consent to Use of Data: You agree that Licensor may collect and use technical data and 
                        related information—including but not limited to technical information the application 
                        software—that is gathered periodically to facilitate the provision of software updates, product 
                        support, and other services to you (if any) related to the Licensed Application. Licensor may use 
                        this information, as long as it is in a form that does not personally identify you, to improve its 
                        products or to provide services or technologies to you. The Licensed Application may 
                        access–but will not store–confidential information inputted in Space Cockpit to provide services 
                        to you.
                    </p>  
                    <p>
                        c. Termination. This Standard EULA is effective until terminated by you or Licensor. Your rights 
                        under this Standard EULA will terminate automatically if you fail to comply with any of its terms. 
                    </p>
                    <p>
                        d. Third-Party Websites. The Licensed Application may contain (or you may be sent via the Site) 
                        links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, 
                        pictures, designs, music, sound, video, information, applications, software, and other content or 
                        items belonging to or originating from third-parties ("Third-Party Content"). Such Third-Party 
                        Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, 
                        appropriateness, or completeness by Licensor, and Licensor is not responsible for any 
                        Third-Party Websites accessed through the Licensed Application or any Third-Party Content 
                        posted on, available through, or installed from the Licensed Application, including the content, 
                        accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained 
                        in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the 
                        use or installation of any Third-Party Websites or any Third-Party Content does not imply 
                        approval or endorsement thereof by Licensor. If you decide to leave the Licensed Application 
                        and access the Third-Party Websites or to use or install any Third-Party Content, you do so at 
                        your own risk, and you should be aware these Legal Terms no longer govern. You should 
                        review the applicable terms and policies, including privacy and data gathering practices, of any 
                        website to which you navigate from the Licensed Application or relating to any applications you 
                        use or install from the Licensed Application. You shall hold Licensor blameless from any losses 
                        sustained by you or harm caused to you relating to or resulting in any way from any Third-Party 
                        Content or any contact with Third-Party Websites.
                    </p>
                    <p>
                        e. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE 
                        LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT 
                        PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES 
                        PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS IS" 
                        AND "AS AVAILABLE," WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND 
                        LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT 
                        TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR 
                        STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR 
                        CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A 
                        PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF 
                        NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION 
                        OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL 
                        CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE 
                        DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, 
                        OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED 
                        WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, 
                        SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
                    </p>
                    <p>
                        f. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL 
                        LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, 
                        INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT 
                        LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS 
                        INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT 
                        OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, 
                        HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, 
                        OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF
                        SUCH DAMAGES. 
                    </p>
                    <p>
                        g. This Agreement and the relationship between you and Saber Astronautics shall be governed 
                        by the laws of the State of Colorado. You and Saber Astronautics consent to, and waive all 
                        defenses of lack of personal jurisdiction, and forum non conveniens with respect to venue and 
                        jurisdiction in such state and federal courts. Application of the United Nations Convention on 
                        Contracts for the International Sale of Goods and the Uniform Computer Information 
                        Transaction Act (UCITA) are excluded from these Legal Terms.
                    </p>
                    <p>
                        h. Every effort is made to keep the website up and running smoothly. However, we take no 
                        responsibility for, and will not be liable for, the website being temporarily unavailable due to 
                        technical issues beyond our control.
                    </p>
                    <p>
                        i. These terms and conditions may be amended from time to time. Your continued use of our 
                        website following any such amendments will be deemed to be confirmation that you accept 
                        those amendments.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className="bg-dark border-0">
                <Button 
                    variant="secondary" 
                    onClick={onHide}
                    style={{ 
                        backgroundColor: '#333',
                        borderColor: '#333'
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    variant="warning"
                    onClick={onAccept}
                    style={{ 
                        backgroundColor: '#f5bc59',
                        borderColor: '#f5bc59'
                    }}
                >
                    Accept
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LicenseAgreementModal; 