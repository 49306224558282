import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import { AppState } from "../lib/context/AppProvider";
import { Container, Button, Modal } from 'react-bootstrap';
import LicenseAgreementModal from "../components/LicenseAgreementModal";
import PurchaseConfirmationModal from "../components/PurchaseConfirmationModal";

// Helper function to merge app data
const mergeAppData = (appDetails, catalogApp, isPurchased) => ({
    ...appDetails,
    Title: catalogApp?.Title,
    Company: catalogApp?.Company,
    HighSideCompatible: catalogApp?.HighSideCompatible,
    Price: catalogApp?.Price,
    isPurchased,
    images: [
        { id: 0, data: appDetails.Image1 },
        { id: 1, data: appDetails.Image2 },
        { id: 2, data: appDetails.Image3 }
    ].filter(img => img.data)
});

// Component for the company logo
const CompanyLogo = ({ logo, company }) => (
    logo ? (
        <img 
            src={`data:image/png;base64,${logo}`}
            alt={`${company} logo`}
            style={{ 
                width: '64px', 
                height: '64px',
                minWidth: '64px',
                objectFit: 'contain'
            }}
        />
    ) : (
        <div 
            className="bg-secondary"
            style={{ 
                width: '64px', 
                height: '64px',
                minWidth: '64px'
            }}
        />
    )
);

// Component for the high-side compatibility badge
const HighSideCompatibilityBadge = () => (
    <div className="d-flex align-items-center"
        title="This application does not need to access outside services to work."
    >
        <svg className="me-1" width="16" height="16" fill="none" stroke="white" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
        <small className="text-white">High-Side Compatible</small>
    </div>
);

// Component for the app actions (purchase/delete buttons)
const AppActions = ({ app, user, onShowLicense, openDropdownId, onDropdownToggle, onDeleteClick }) => {
    const getButtonStyle = (isPurchased) => {
        let backgroundColor;
        let borderColor;
        let cursor;
        let opacity = 1;

        if (isPurchased) {
            backgroundColor = '#8B7355';
            borderColor = '#8B7355';
            cursor = 'not-allowed';
        } else if (user.isGuest) {
            backgroundColor = '#666666';
            borderColor = '#666666';
            cursor = 'not-allowed';
            opacity = 0.8;
        } else {
            backgroundColor = '#f5bc59';
            borderColor = '#f5bc59';
            cursor = 'pointer';
        }

        return {
            backgroundColor,
            borderColor,
            flex: 1,
            cursor,
            opacity,
            height: '38px'
        };
    };

    const getDropdownButtonStyle = (isPurchased) => ({
        backgroundColor: isPurchased ? '#f5bc59' : '#666666',
        borderColor: isPurchased ? '#f5bc59' : '#666666',
        padding: '0 8px',
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        cursor: isPurchased ? 'pointer' : 'not-allowed',
        opacity: isPurchased ? 1 : 0.65
    });

    const getButtonText = (app, user) => {
        if (user.isGuest) {
            return 'Must be logged in';
        }
        if (app.isPurchased) {
            return 'Purchased';
        }
        return app.Price === 0 ? 'Get' : `${app.Price} credits`;
    };

    const isOpen = openDropdownId === app.Id;

    return (
        <div className="d-flex gap-1">
            <Button
                variant="warning"
                onClick={(e) => {
                    if (!app.isPurchased) {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowLicense();
                    }
                }}
                style={getButtonStyle(app.isPurchased)}
                disabled={user.isGuest || app.isPurchased}
            >
                {getButtonText(app, user)}
            </Button>
            {!user.isGuest && (
                <div className="dropdown" style={{ position: 'relative' }}>
                    <Button
                        variant="warning"
                        className="dropdown-toggle dropdown-toggle-split"
                        onClick={(e) => {
                            if (app.isPurchased) {
                                onDropdownToggle(e, app.Id);
                            }
                        }}
                        style={getDropdownButtonStyle(app.isPurchased)}
                        disabled={!app.isPurchased}
                    >
                        <span className="visually-hidden">Toggle Dropdown</span>
                    </Button>
                    <ul 
                        className={`dropdown-menu dropdown-menu-end ${isOpen ? 'show' : ''}`}
                        style={{ 
                            backgroundColor: '#2a2a2a',
                            pointerEvents: app.isPurchased ? 'auto' : 'none',
                            position: 'absolute',
                            top: '100%',
                            right: 0,
                            marginTop: '2px',
                            maxHeight: '300px',
                            overflowY: 'auto',
                            minWidth: '200px'
                        }}
                    >
                        <li>
                            <button 
                                className="dropdown-item"
                                onClick={() => onDeleteClick()}
                                style={{ 
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '8px 16px',
                                    color: '#ff4444'
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#3a3a3a';
                                    e.currentTarget.style.color = '#ff6666';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                    e.currentTarget.style.color = '#ff4444';
                                }}
                            >
                                Delete application
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

// Component for the image carousel
const ImageCarousel = ({ images, currentImageIndex, onImageChange }) => {
    const getCurrentImage = () => images[currentImageIndex]?.data || '';

    return (
        <div className="position-relative mb-2">
            <div className="text-center">
                <img
                    src={`data:image/png;base64,${getCurrentImage()}`}
                    alt={`Screenshot ${currentImageIndex + 1}`}
                    style={{ 
                        maxHeight: '80vh',
                        maxWidth: '100%',
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
                
                {images.length > 1 && (
                    <>
                        <button 
                            className="position-absolute top-50 start-0 translate-middle-y bg-transparent border-0 ps-3"
                            style={{ 
                                fontSize: '2rem', 
                                cursor: 'pointer',
                                color: '#f5bc59',
                                opacity: currentImageIndex === 0 ? 0.5 : 1,
                                transition: 'opacity 0.2s'
                            }}
                            onClick={() => onImageChange(Math.max(0, currentImageIndex - 1))}
                            disabled={currentImageIndex === 0}
                        >
                            ◀
                        </button>
                        <button 
                            className="position-absolute top-50 end-0 translate-middle-y bg-transparent border-0 pe-3"
                            style={{ 
                                fontSize: '2rem', 
                                cursor: 'pointer',
                                color: '#f5bc59',
                                opacity: currentImageIndex === images.length - 1 ? 0.5 : 1,
                                transition: 'opacity 0.2s'
                            }}
                            onClick={() => onImageChange(Math.min(images.length - 1, currentImageIndex + 1))}
                            disabled={currentImageIndex === images.length - 1}
                        >
                            ▶
                        </button>
                    </>
                )}
            </div>

            {images.length > 1 && (
                <div className="d-flex justify-content-center gap-2 pb-2">
                    {images.map((image) => (
                        <button
                            key={`image-dot-${image.id}`}
                            onClick={() => onImageChange(image.id)}
                            className="rounded-circle border-0"
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: currentImageIndex === image.id ? '#f5bc59' : '#666',
                                cursor: 'pointer'
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const SamApp = () => {
    const [app, setApp] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showLicenseModal, setShowLicenseModal] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    
    const { appId } = useParams();
    const navigate = useNavigate();
    const { getData, postData, user } = useContext(AppState);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isDropdownOpen && !event.target.closest('.dropdown')) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [isDropdownOpen]);

    useEffect(() => {
        const fetchAppDetails = async () => {
            if (!appId) return;

            try {
                setLoading(true);
                const [catalogData, appDetails] = await Promise.all([
                    getData('apps'),
                    getData(`apps/${appId}`)
                ]);
                
                const catalogApp = catalogData.find(app => app.Id === parseInt(appId));
                
                if (user.isGuest) {
                    setApp(mergeAppData(appDetails, catalogApp, false));
                } else {
                    const userApps = await getData('user-apps');
                    const purchasedAppIds = new Set(userApps?.map(app => parseInt(app.AppId)) || []);
                    const isPurchased = purchasedAppIds.has(parseInt(appId));
                    setApp(mergeAppData(appDetails, catalogApp, isPurchased));
                }
            } catch (error) {
                console.error("Error fetching app details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAppDetails();
    }, [appId, getData, user.isGuest]);

    const handleBack = () => navigate('/sam');
    const handleLicenseAccept = () => {
        setShowLicenseModal(false);
        setShowPurchaseModal(true);
    };
    
    const handlePurchaseSuccess = async () => {
        try {
            // First deduct credits if it's a paid app
            if (app?.Price > 0) {
                const updatedCredits = await postData('user-credits', { credits: app.Price });
                if (!updatedCredits) {
                    throw new Error('Failed to update credits');
                }
            }
            
            // Then make the purchase
            const purchaseResult = await postData(`apps/${appId}/purchase`);
            if (!purchaseResult?.ok) {
                throw new Error('Purchase failed');
            }

            // Update the app state to reflect purchase
            setApp(prevApp => ({ ...prevApp, isPurchased: true }));
            setShowPurchaseModal(false);
            return true; // Indicate success
        } catch (error) {
            console.error('Error during purchase:', error);
            throw error;
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await postData(`user-apps/${appId}`, {}, 'DELETE');
            setApp(prevApp => ({ ...prevApp, isPurchased: false }));
        } catch (error) {
            console.error('Error deleting app:', error);
        } finally {
            setShowDeleteModal(false);
            setIsDropdownOpen(false);
        }
    };

    const handleDropdownToggle = (e, appId) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    if (loading) {
        return (
            <div className="min-vh-100 bg-dark">
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} showCredits={true} />
                <Container fluid className="py-4">
                    <div className="text-white">Loading...</div>
                </Container>
            </div>
        );
    }

    if (!app) {
        return (
            <div className="min-vh-100 bg-dark">
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} showCredits={true} />
                <Container fluid className="py-4">
                    <div className="text-white">App not found</div>
                </Container>
            </div>
        );
    }

    return (  
        <div className="min-vh-100 bg-dark d-flex flex-column">
            <Header showSideBarMenu={true} showDashboardMenu={false} height={56} showCredits={true} />
            
            <div className="overflow-auto" style={{ height: "calc(100vh - 56px)" }}>
                <div className="p-4 pb-2">
                    <div className="d-flex align-items-start mb-3" style={{ gap: 'clamp(2rem, 5vw, 8rem)' }}>
                        <Button
                            variant="warning"
                            onClick={handleBack}
                            className="d-flex align-items-center gap-2"
                            style={{ 
                                backgroundColor: '#f5bc59', 
                                borderColor: '#f5bc59',
                                padding: '8px 16px',
                                fontSize: '1rem',
                                minWidth: 'fit-content'
                            }}
                        >
                            <span style={{ fontSize: '1.2rem', lineHeight: 1 }}>←</span>
                            <span>Back</span>
                        </Button>

                        <div className="d-flex justify-content-between align-items-start flex-grow-1" style={{ minWidth: 0 }}>
                            <div className="d-flex gap-3" style={{ minWidth: 0, maxWidth: 'calc(100% - 140px)' }}>
                                <CompanyLogo logo={app.CompanyLogo} company={app.Company} />
                                <div style={{ minWidth: 0 }}>
                                    <h1 className="text-white mb-2" style={{ fontSize: '2rem' }}>{app.Title}</h1>
                                    <div className="d-flex flex-wrap gap-2">
                                        <div style={{ color: '#f5bc59' }}>{app.Company}</div>
                                        {Boolean(app.HighSideCompatible) && <HighSideCompatibilityBadge />}
                                    </div>
                                </div>
                            </div>
                            <AppActions 
                                app={app}
                                user={user}
                                onShowLicense={() => setShowLicenseModal(true)}
                                onDeleteClick={() => setShowDeleteModal(true)}
                                openDropdownId={isDropdownOpen ? app.Id : null}
                                onDropdownToggle={handleDropdownToggle}
                            />
                        </div>
                    </div>
                </div>

                <Container fluid className="px-4">
                    <ImageCarousel 
                        images={app.images}
                        currentImageIndex={currentImageIndex}
                        onImageChange={setCurrentImageIndex}
                    />

                    <div className="mb-4">
                        <h2 className="text-white mb-3" style={{ fontWeight: 'normal' }}>Description</h2>
                        <div style={{ backgroundColor: 'rgba(51, 51, 51, 0.95)' }} className="rounded p-4">
                            <div 
                                className="text-white" 
                                style={{ lineHeight: '1.5' }}
                                dangerouslySetInnerHTML={{ __html: app?.DescriptionLong }}
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-white mb-3" style={{ fontWeight: 'normal' }}>What's New</h2>
                        <div style={{ backgroundColor: 'rgba(51, 51, 51, 0.95)' }} className="rounded p-4">
                            <div className="d-flex align-items-center gap-3 mb-3">
                                {app?.Version && (
                                    <>
                                        <span className="text-white">Version {app.Version}</span>
                                        <span className="text-white opacity-75">|</span>
                                    </>
                                )}
                                {app?.UpdatedAt && (
                                    <span className="text-white">
                                        {new Date(app.UpdatedAt).toLocaleDateString('en-US', { 
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </span>
                                )}
                            </div>
                            {app?.ReleaseNotes && (
                                <div className="text-white ps-3">
                                    {app.ReleaseNotes.split('\n').map((note, index) => (
                                        <div key={`release-note-${btoa(note.substring(0, 20))}`}>- {note}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </div>

            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
                backdrop="static"
                style={{ color: 'white' }}
            >
                <Modal.Header closeButton style={{ backgroundColor: '#2a2a2a', borderBottom: '1px solid #3a3a3a' }}>
                    <Modal.Title style={{ color: 'white' }}>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#2a2a2a' }}>
                    Are you sure you want to delete {app?.Title}? You may lose access to previous in-app purchases.
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#2a2a2a', borderTop: '1px solid #3a3a3a' }}>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <LicenseAgreementModal
                show={showLicenseModal}
                onHide={() => setShowLicenseModal(false)}
                onAccept={handleLicenseAccept}
                appTitle={app?.Title}
            />

            <PurchaseConfirmationModal
                show={showPurchaseModal}
                onHide={() => setShowPurchaseModal(false)}
                onConfirm={handlePurchaseSuccess}
                appTitle={app?.Title}
                appId={appId}
                appPrice={app?.Price}
            />
        </div>
    );
};

export default SamApp;
