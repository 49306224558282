import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "react-bootstrap";

const GroupModal = ({ 
    show, 
    onHide, 
    typeText, 
    buttonVariant = "primary",
    onSave,
    editMode = false,
    existingGroup,
    modalBody,
    makeGroup,
}) => {
    const [groupName, setGroupName] = useState(existingGroup?.name);

    // Reset state when modal opens/closes or when editMode changes
    useEffect(() => {
        if (show) {
            if (editMode) {
                setGroupName(existingGroup?.name);
            } else {
                setGroupName("");
            }
        }
    }, [show, editMode, existingGroup]);

    const getModalTitle = () => {
        const actionText = editMode ? "Edit" : "Create New";
        return `${actionText} ${typeText}`;
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton className="bg-dark text-white">
                <Modal.Title>
                    {getModalTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="group-name">{typeText} Name</Form.Label>
                        <Form.Control
                            id="group-name"
                            type="text"
                            value={groupName ?? ""}
                            onChange={(e) => setGroupName(e.target.value)}
                            className="bg-dark text-white"
                            aria-label="Group Name"
                        />
                    </Form.Group>
                    {modalBody}
                </Form>
            </Modal.Body>
            <Modal.Footer className="bg-dark text-white">
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button 
                    variant={buttonVariant}
                    onClick={() => onSave(makeGroup(groupName))}
                    disabled={!groupName || !makeGroup(groupName)}
                >
                    {editMode ? "Update" : "Save"} {typeText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

GroupModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    typeText: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
};

export default GroupModal;