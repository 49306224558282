import React, { useState, useEffect, useContext } from "react";
import { AppState } from "../lib/context/AppProvider";
import Header from "../components/Header";
import "../styles/page.css";
import ConstellationSettingsGroup from "../components/settings/ConstellationSettingsGroup";
import { Accordion, Button } from "react-bootstrap";
import SettingsGroup from "../components/settings/SettingsGroup";
import ProfileModal from "../components/settings/ProfileModal";
import { plural } from "../util/Utils";
import { DASHBOARDS } from "../lib/context/DashboardProvider";

const accordionBodyClass = "p-0 mt-3 ps-4 pe-4 text-white";

const profileNameHeader = (name, editButtonVariant, handleEditGroup, handleDeleteGroup, handleDuplicateGroup) => (
    <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
            <h6 className="mb-1 text-white">{name}</h6>
        </div>
        <div>
            <Button
                variant={editButtonVariant}
                size="sm"
                className="me-2"
                onClick={() => handleEditGroup(name)}
            >
                Edit
            </Button>
            <Button
                variant="outline-warning"
                size="sm"
                className="me-2"
                onClick={() => handleDuplicateGroup(name)}
            >
                Duplicate
            </Button>
            <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => handleDeleteGroup(name)}
            >
                Delete
            </Button>
        </div>
    </div>
);

const lenOrZero = (arr) => arr?.length ?? 0;

const ProfileSettings = ({satellites, sources}) => {
    const {profiles, setProfiles} = useContext(AppState);
    
    const editButtonVariant = "outline-info";
    
    const renderGroup = (
        name, group, {handleEditGroup, handleDeleteGroup, handleDuplicateGroup}
    ) => {
        if(!group) return null;
        const selectedSats = group.satellites;
        const selectedSources = group.sources;
        const selectedDashes = group.dashboards;

        const numSats = lenOrZero(selectedSats);
        const numSources = lenOrZero(selectedSources);
        const numDashes = lenOrZero(selectedDashes);

        const sectionClass = "d-flex justify-content-between align-items-center mt-2 mb-2";
        
        return (
            <div 
                key={name}
                className="p-3 mb-2 rounded"
                style={{ backgroundColor: "#1a1d20" }}
            >
                {profileNameHeader(name, editButtonVariant, handleEditGroup, handleDeleteGroup, handleDuplicateGroup)}

                {/* SATELLITES  */}
                <div className={sectionClass}>
                    <div>
                        <small className="text-light">
                            {numSats} {plural("satellite", selectedSats?.length, numSats)}
                        </small>
                    </div>
                </div>
                {numSats > 0 && (
                    <div className="settings-group-list" >
                        {selectedSats.map(satId => {
                            const satellite = satellites.find(s => s.id === satId);
                            return (
                                <div key={satId} className="small text-white ps-2">
                                    {satellite ? `${satId} - ${satellite.name}` : satId}
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* SOURCES */}
                <div className={sectionClass}>
                    <div>
                        <small className="text-light">
                            {numSources === 0 ? "All sources" : `${numSources} ${plural("source", numSources)}`}
                        </small>
                    </div>
                </div>
                {numSources > 0 && (
                    <div className="settings-group-list">
                        {selectedSources.map(source => {
                            return (
                                <div key={source} className="small text-white ps-2">
                                    {source}
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* DASHES */}
                <div className={sectionClass}>
                    <div>
                        <small className="text-light">
                            {numDashes === (DASHBOARDS.length+1) ? "All dashboards" : `${numDashes} ${plural("dashboard", numDashes)}`}
                        </small>
                    </div>
                </div>
                {numDashes > 0 && (
                    <div className="settings-group-list">
                        {selectedDashes.map(d => {
                            return (
                                <div key={d} className="small text-white ps-2">
                                    {d}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    return (
        <Accordion.Item eventKey="profiles" className="bg-dark border-0 mt-3">
            <Accordion.Header className="settings-accordion-header">
                <h4 className="settings-section-title m-0">PROFILES</h4>
            </Accordion.Header>
            <Accordion.Body className={accordionBodyClass}>

                <SettingsGroup 
                    groupType="profile"
                    title={<>Profiles</>}
                    description={"Each profile specifies a set of satellite and source selections that can be loaded into dashboards by default. You can switch between the profiles you create on the dashboard pages, and your last selected profile will be remembered."}
                    testid="profiles"
                    typeText={"Profile"}
                    buttonVariant="info"
                    editButtonVariant="outline-info"
                    enabled={true}
                    groups={profiles}
                    setGroups={(newGroups) => setProfiles(newGroups)}
                    saveGroups={(newGroups) => setProfiles(newGroups)}
                    modalType={ProfileModal}
                    modalProps={{satellites: satellites, sources: sources}}
                    renderGroup={renderGroup}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
};

function Settings() {
    const { user, getData } = useContext(AppState);
    const [satellites, setSatellites] = useState([]);
    const [sources, setSources] = useState([]);
    const [activeKeys, setActiveKeys] = useState(["profiles"]);

    useEffect(() => {
        // Check if URL has #constellations hash
        if (window.location.hash === '#constellations') {
            setActiveKeys(['constellations']);
        }
        else if (window.location.hash === '#profiles') {
            setActiveKeys(['profiles']);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [satData, sourceData] = await Promise.all([
                    getData("satellites"),
                    getData("providers")
                ]);

                if (!satData || !Array.isArray(satData)) {
                    console.error("Invalid satellite data received:", satData);
                    return;
                }

                const formattedSats = satData.map(sat => {
                    // If the data is already in the correct format, return it as is
                    if (typeof sat === 'object' && sat.id && sat.name) {
                        return sat;
                    }
                    // Otherwise, parse the string format
                    const [id, name] = sat.split(" - ");
                    return { id: id.trim(), name: name.trim() };
                });

                setSatellites(formattedSats);

                if(sourceData) {
                    setSources(sourceData?.sort());
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if(!user.isGuest) {
            fetchData();
        }
    }, [getData, user, setSatellites]);

    return (  
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <div id="page-background">
                <section className="settings-section">
                    <Accordion 
                        activeKey={activeKeys}
                        onSelect={(key) => setActiveKeys(!activeKeys?.includes(key) ? [key] : [])}
                        className="mb-3 settings-accordion"
                    >
                        <Accordion.Item eventKey="constellations" className="bg-dark border-0">
                            <Accordion.Header className="settings-accordion-header">
                                <h4 className="settings-section-title m-0">CONSTELLATIONS</h4>
                            </Accordion.Header>
                            <Accordion.Body className={accordionBodyClass}>
                                <ConstellationSettingsGroup satellites={satellites} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="windu" className="bg-dark border-0 mt-3">
                            <Accordion.Header className="settings-accordion-header">
                                <h4 className="settings-section-title m-0">WINDU</h4>
                            </Accordion.Header>
                            <Accordion.Body className={accordionBodyClass}>
                                <p className="text-light">Windu is currently in development. Coming soon.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <ProfileSettings satellites={satellites} sources={sources} />
                    </Accordion>
                </section>
            </div>
        </>
    );
}

export default Settings;
