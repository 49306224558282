import React, {useState, useEffect, useContext} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import logo from "../lib/assets/img/logo.png";
import mobileLogo from "../lib/assets/img/mobileLogo.png";
import spaceCockpitLogo from "../lib/assets/img/spacecockpit.png";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import { FiMenu  } from "react-icons/fi";
import SideBar from "./SideBar";
import { DashboardState } from "../lib/context/DashboardProvider";
import DashboardMenu from "./DashboardMenu";
import { AppState } from "../lib/context/AppProvider";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

export const MAX_HEADER_BUTTONS = [2, 4, 5, 9, 12];

const useMaxButtons = () => {
    const isVeryWideScreen = useMediaQuery({query: "(min-width: 1950px)"});
    const isQuiteWideScreen = useMediaQuery({query: "(min-width: 1750px)"}) && !isVeryWideScreen;
    const isWideScreen = useMediaQuery({query: "(min-width: 950px)"}) && !isQuiteWideScreen && !isVeryWideScreen;
    const isNarrowScreen = useMediaQuery({query: "(min-width: 700px)"}) && !isWideScreen && !isVeryWideScreen && !isQuiteWideScreen;
    const isMobile = useMediaQuery({query: "(max-width: 700px)"});

    let maxButtons;
    if(isVeryWideScreen) {
        maxButtons = MAX_HEADER_BUTTONS[4];
    }
    else if(isQuiteWideScreen) {
        maxButtons = MAX_HEADER_BUTTONS[3];
    }
    else if(isWideScreen) {
        maxButtons = MAX_HEADER_BUTTONS[2];
    } else {
        maxButtons = isNarrowScreen ? MAX_HEADER_BUTTONS[1] : MAX_HEADER_BUTTONS[0];
    }

    return {isMobile, isNarrowScreen, maxButtons};
};

const Header = ({
    height,
    showDashboardMenu = true,
    showSideBarMenu = true,
    showCredits = false,
    showProfiles = false,
    isSpaceCockpit = false
}) => {
    const {
        user, getData, 
        selectedProfile, setSelectedProfile, profiles
    } = useContext(AppState);
    const [showMenu, setShowMenu] = useState(false);
    const [numAlerts, setNumAlerts] = useState(0);
    const [creditBalance, setCreditBalance] = useState(0);
    const navigate = useNavigate();

    let dashName = useContext(DashboardState)?.dashboardName;
    if(isSpaceCockpit) {
        dashName = "Space Cockpit";
    }
    const excludedProfile = !selectedProfile?.dashboards?.includes(dashName);

    // Fetch credit balance
    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const credits = await getData('user-credits');
                setCreditBalance(credits?.balance || 0);
            } catch (error) {
                console.error('Error fetching credit balance:', error);
                setCreditBalance(0);
            }
        };
        
        if (!user.isGuest && showCredits) {
            fetchCredits();
        }
    }, [getData, user.isGuest, showCredits]);

    // Preload the logo images
    useEffect(() => {
        const preloadImages = [logo, spaceCockpitLogo];
        preloadImages.forEach(src => {
            const img = new Image();
            img.src = src;
        });
    }, []);

    const style = {
        height: height,
        minHeight: height,
    };
    
    const {isMobile, isNarrowScreen, maxButtons} = useMaxButtons();

    const handleShow = () => {
        setShowMenu(!showMenu);
        setNumAlerts(0);
    };
    const handleClose = () => {
        setShowMenu(false);
    };
    const handleRefresh = () => {
        navigate("/home");
    };

    const showUsername = !((isMobile || isNarrowScreen) && showDashboardMenu && DashboardState);

    return (
        <div className="bg-dark" data-testid="mock-header">
            <Row className="topbar g-0 p-0" style={style}>
                <Col xs={1} className="pt-2">
                    <img
                        className="logo"
                        src={(isMobile || isNarrowScreen) ? mobileLogo : logo}
                        onClick={handleRefresh}
                        alt="logo"
                        height={38}
                        style={{cursor: "pointer", marginLeft:"10px"}}
                    />
                </Col>
                
                    <Col 
                        className="text-center"
                        style={{
                            marginTop:"10px",
                        }}
                    >
                        {showDashboardMenu && DashboardState && (
                            <DashboardMenu maxButtons={maxButtons} />
                        )}
                    </Col>

                {showSideBarMenu && (
                    <>
                    <Col xs={1} style={{
                        marginTop:"10px",
                        minWidth: showUsername ? "250px" : undefined
                    }} >
                        <div 
                            className="float-end"
                            style={{
                                marginRight:"10px",
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "nowrap"
                            }}>
                                {showUsername && (
                                <div className="d-flex align-items-center me-3" style={{ whiteSpace: "nowrap" }}>
                                    {showProfiles && (<div className="text-white d-flex align-items-center me-3" style={{ whiteSpace: "nowrap" }}>
                                        <DropdownButton
                                            as={ButtonGroup}
                                            title={(selectedProfile && !excludedProfile) ? selectedProfile.name : "No Profile"}
                                            id="bg-nested-dropdown"
                                            variant="dark"
                                        >
                                            <Dropdown.Header>Select a profile</Dropdown.Header>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                eventKey={"none"}
                                                onClick={(e) => setSelectedProfile(null)}
                                                key={`dropdown-profile-none`}
                                                active={selectedProfile === null || excludedProfile}
                                            >
                                                No Profile
                                            </Dropdown.Item>
                                            {profiles && Object.entries(profiles).map(([name, group]) => (
                                                <Dropdown.Item
                                                    eventKey={name}
                                                    onClick={(e) => setSelectedProfile(name)}
                                                    key={`dropdown-profile-${name.replace(/\s+/g, '-')}`}
                                                    active={selectedProfile?.name === name && !excludedProfile}
                                                    disabled={group?.dashboards?.length > 0 && !group.dashboards.includes(dashName)}
                                                >
                                                    {name}
                                                </Dropdown.Item>
                                            ))}
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                eventKey={"add"}
                                                onClick={(e) => navigate("/settings#profiles")}
                                                key={`dropdown-profile-add`}
                                            >
                                                Add/Edit Profile
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>)}
                                    {!user.isGuest && showCredits && (
                                        <div className="text-white d-flex align-items-center me-3" style={{ whiteSpace: "nowrap" }}>
                                            Credit Balance: {creditBalance} credits<span className="ms-3 opacity-50">|</span>
                                        </div>
                                    )}
                                    <div className="text-white">
                                        {user.username}
                                    </div>
                                </div>
                                )}
                                <Button
                                    variant="dark"
                                    onClick={handleShow}
                                >
                                    {numAlerts ? (<div style={{position: "absolute",
                                        color:"rgb(255, 193, 7)",
                                        backgroundColor:"rgb(33, 37, 41)",
                                        fontSize: "12px",
                                        marginTop:"20px",
                                        marginLeft:"-20px",
                                        borderRadius:"100%",
                                        width:"20px",
                                        height:"20px"
                                    }}>{numAlerts}</div>) : null}
                                    <FiMenu/>
                                </Button>
                        </div>
                    </Col>
                    <SideBar
                        visible={showMenu}
                        handleClose={handleClose}
                        setNumAlerts={setNumAlerts}
                        spaceCockpitLogo={spaceCockpitLogo}
                    />
                    </>
                )}
            </Row>
        </div>
    );
};

Header.propTypes = {
    height: PropTypes.node.isRequired,
    showDashboardMenu: PropTypes.bool,
    showSideBarMenu: PropTypes.bool,
    className: PropTypes.string,
};

export default Header;
