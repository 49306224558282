import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Container, Row, Col, Badge, Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

import { AppState } from '../lib/context/AppProvider';
import Header from '../components/Header';
import { getDomainUrl } from "../util/Utils";
import SpaceCockpitDockContainer from '../components/SpaceCockpitDockContainer';
import winduImage from '../lib/assets/img/windu.png';

const TabsComponent = ({ tabs, hasDetails }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const tabStyle = {
        padding: '0.5rem 1rem',
        backgroundColor: '#1a1d20',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px 4px 0 0',
        marginRight: '0.5rem',
        opacity: 0.7,
        transition: 'opacity 0.2s'
    };

    const activeTabStyle = {
        ...tabStyle,
        opacity: 1,
        backgroundColor: '#2a2d30'
    };

    const tabWrapperStyle = {
        flex: 1,
        backgroundColor: '#2a2d30',
        borderRadius: '0 4px 4px 4px',
        padding: '0.5rem',
        overflow: 'hidden',
        position: 'relative',
        minHeight: '400px'
    };

    const tabContentStyle = {
        top: '0.5rem',
        left: '0.5rem',
        right: '0.5rem',
        bottom: '0.5rem',
        height: '100%',
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.2s'
    };

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            height: '725px',
            marginTop: '1rem'
        }}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    {tabs.map(tab => (
                        <button 
                            key={tab.id}
                            style={activeTab === tab.id ? activeTabStyle : tabStyle}
                            onClick={() => setActiveTab(tab.id)}
                            aria-label={`Switch to ${tab.label}`}
                            aria-selected={activeTab === tab.id}
                            role="tab"
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
            <div style={tabWrapperStyle}>
                {tabs.map(tab => (
                    activeTab === tab.id && (
                        <div 
                            key={tab.id} 
                            style={tabContentStyle}
                        >
                            {tab.element}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

TabsComponent.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        element: PropTypes.node.isRequired
    })).isRequired,
    hasDetails: PropTypes.bool
};

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#1a1d20'
};

function Alert() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { getData } = useContext(AppState);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    const [currentSpaceCockpitPosition, setSpaceCockpitPositionRaw] = useState({
        position: "right", 
        size: 0.33
    });

    const setSpaceCockpitPosition = (pos) => setSpaceCockpitPositionRaw((c) => ({
        position: pos, 
        size: c.size
    }));

    useEffect(() => {
        const fetchUserDetails = async () => {
            const details = await getData("user-details");
            setUserDetails(details);
        };
        fetchUserDetails();
    }, [getData]);

    useEffect(() => {
        const fetchAlert = async () => {
            try {
                setLoading(true);
                const now = new Date();
                const sevenDaysAgo = new Date(now);
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

                const alertData = await getData('alerts', {
                    unique_id: id,
                    start_timestamp: sevenDaysAgo.getTime(),
                    end_timestamp: now.getTime()
                });
                setAlert(Array.isArray(alertData) ? alertData[0] : alertData);
            } catch (err) {
                console.error('Error fetching alert:', err);
                setError('Failed to fetch alert details');
            } finally {
                setLoading(false);
            }
        };

        fetchAlert();
    }, [getData, id]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    const getAlertType = () => {
        if (!alert?.UniqueId) return null;
        const parts = alert.UniqueId.split('-');
        return parts.length === 3 ? parts[2] : null;
    };

    const renderElsetHistory = () => {
        if (!userDetails?.AccessToken || !alert) return null;

        const timerange = {
            from: new Date(alert.CreatedAt).getTime() - (7 * 24 * 60 * 60 * 1000), // 7 days before
            to: new Date(alert.CreatedAt).getTime() + (7 * 24 * 60 * 60 * 1000)    // 7 days after
        };

        const sources = [...new Set(alert.Data.alerts.map(data => data.source))].join(',');

        return (
            <div style={{ height: '100%' }}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/PO81TfmVk/elsethistory?var-satnos=${alert.PrimarySatNo}&var-stdDev=0&var-showSourceColor=No&var-source=${sources}&var-accesstoken=${userDetails.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyle}
                    title="ELSET History Dashboard"
                    aria-label="ELSET History Dashboard"
                />
            </div>
        );
    };

    const renderRPO = () => {
        if (!userDetails?.AccessToken || !alert) return null;

        const timerange = {
            from: new Date(alert.CreatedAt).getTime() - (7 * 24 * 60 * 60 * 1000),
            to: new Date(alert.CreatedAt).getTime() + (7 * 24 * 60 * 60 * 1000)
        };

        return (
            <div style={{ height: '100%' }}>
                <iframe
                    src={`${getDomainUrl('spacefana')}/d/be791bd0-9a37-45d7-b956-029966d4b570/rpo?var-satnos=${alert.PrimarySatNo}&var-accesstoken=${userDetails.AccessToken}&from=${timerange.from}&to=${timerange.to}&kiosk`}
                    style={iframeStyle}
                    title="RPO Dashboard"
                    aria-label="RPO Dashboard"
                />
            </div>
        );
    };

    const renderPair = () => {
        if (!alert || !userDetails?.AccessToken) return null;

        return (
            <div style={{ height: '100%' }}>
                <iframe
                    src={`${getDomainUrl('probe')}/dossier/${alert.PrimarySatNo}?accesstoken=${userDetails.AccessToken}`}
                    style={iframeStyle}
                    title="PAIR Dossier"
                    aria-label="PAIR Dossier"
                />
            </div>
        );
    };

    const renderTabs = () => {
        if (!alert || !userDetails) return null;

        const handleCopyNotso = () => {
            const toastId = toast.loading('Copying NOTSO text...');
            navigator.clipboard.writeText(alert.Data.notso)
                .then(() => {
                    toast.update(toastId, { 
                        render: 'NOTSO text copied to clipboard!', 
                        type: 'success',
                        isLoading: false,
                        autoClose: 3000
                    });
                })
                .catch((error) => {
                    toast.update(toastId, { 
                        render: 'Failed to copy NOTSO text', 
                        type: 'error',
                        isLoading: false,
                        autoClose: 3000
                    });
                    console.error('Copy failed:', error);
                });
        };

        const tabs = [
            { id: 'elset', label: 'ELSET History', element: renderElsetHistory() },
            { id: 'rpo', label: 'RPO', element: renderRPO() },
            { id: 'pair', label: 'PAIR', element: renderPair() },
            { id: 'notso', label: 'NOTSO', element: (
                <div style={{ height: '100%', padding: '1rem' }}>
                    <div className="d-flex justify-content-end mb-2">
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={handleCopyNotso}
                            className="d-flex align-items-center gap-2"
                        >
                            <i className="bi bi-clipboard"></i>
                            Copy NOTSO
                        </Button>
                    </div>
                    <pre style={{ 
                        whiteSpace: 'pre-wrap', 
                        wordWrap: 'break-word',
                        color: 'white',
                        backgroundColor: '#1a1d20',
                        padding: '1rem',
                        borderRadius: '4px',
                        height: 'calc(100% - 40px)',
                        overflowY: 'auto',
                        margin: 0
                    }}>
                        {alert.Data.notso}
                    </pre>
                </div>
            )}
        ];

        return (
            <SpaceCockpitDockContainer 
                id="alerts"
                currentSpaceCockpitPosition={currentSpaceCockpitPosition}
                setSpaceCockpitPosition={setSpaceCockpitPosition}
                sats={[...new Set([alert.PrimarySatNo, ...(alert.Data.alerts.flatMap(data => data.secondarySatNos))])]}
                focus={alert.PrimarySatNo}
                time={new Date(alert.CreatedAt).toISOString()}
            >
                <TabsComponent tabs={tabs} hasDetails={true} />
            </SpaceCockpitDockContainer>
        );
    };

    if (loading) {
        return (
            <>
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
                <Container className="mt-4">
                    <div className="text-center">Loading alert details...</div>
                </Container>
            </>
        );
    }

    if (error) {
        return (
            <>
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
                <Container className="mt-4">
                    <div className="text-center text-danger">{error}</div>
                </Container>
            </>
        );
    }

    if (!alert) {
        return (
            <>
                <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
                <Container className="mt-4">
                    <div className="text-center">Alert not found</div>
                </Container>
            </>
        );
    }

    const alertType = getAlertType();

    return (
        <>
            <Header showSideBarMenu={true} showDashboardMenu={false} height={56} />
            <Container fluid className="mt-4" style={{ maxWidth: '95%' }}>
                <Card bg="dark" text="white">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <Button 
                            variant="warning"
                            onClick={() => navigate("/home")}
                            className="d-flex align-items-center gap-2"
                        >
                            <span style={{ fontSize: '1.2rem', lineHeight: 1 }}>←</span>
                            <span>Home</span>
                        </Button>
                        <h4 className="mb-0 flex-grow-1 text-center">Alert Details</h4>
                        <div className="d-flex flex-column align-items-end">
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip>
                                        <strong>Alert Levels:</strong><br/>
                                        <strong style={{ color: '#0dcaf0' }}>Info:</strong> RSO is <u>not</u> ranked<br/>
                                        <strong style={{ color: '#ffc107' }}>Warning:</strong> RSO is ranked<br/>
                                        <strong style={{ color: '#dc3545' }}>Danger:</strong> RSO is ranked, and has dangerous RPO's
                                    </Tooltip>
                                }
                            >
                                <Badge bg={alert.Level.toLowerCase()} 
                                    className={alert.Level.toLowerCase() === 'warning' ? 'text-dark' : ''}
                                    style={{ cursor: 'help' }}>
                                    {alert.Level.toUpperCase()}
                                </Badge>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip>
                                        Windu: "May the force be with you"
                                    </Tooltip>
                                }
                            >
                                <img 
                                    src={winduImage} 
                                    alt="Windu" 
                                    className="d-none d-md-block"
                                    style={{ 
                                        width: '125px', 
                                        marginTop: '3rem',
                                        position: "absolute",
                                        cursor: 'help'
                                    }} 
                                />
                            </OverlayTrigger>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "calc(100vh - 56px - 12px - 150px)"
                        }}>
                            <Row className="mb-4">
                                <Col md={6}>
                                    <p>
                                        <Badge bg="primary" className="me-2">
                                            {alert.PrimarySatNo}
                                        </Badge>
                                        {alert.PrimarySatName}
                                    </p>
                                    <p>{alert.Description}</p>
                                </Col>
                                <Col md={6}>
                                    <p><strong>Created:</strong> {formatDate(alert.CreatedAt)}</p>
                                    <p><strong>Trigger:</strong> {alert.TriggerCondition}</p>
                                </Col>
                            </Row>

                            {alert.Data.alerts && alert.Data.alerts.length > 0 && (
                                <Row className="mb-4">
                                    <Col>
                                        <h5>Provider Consensus ({alert.Data.alerts.length})</h5>
                                        <div style={{ maxHeight: '225px', overflowY: 'auto' }}>
                                            <Table
                                                variant="dark" 
                                                hover 
                                                responsive
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Provider</th>
                                                        <th>Event Time</th>
                                                        <th>Overview</th>
                                                        <th>Deviation</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {alert.Data.alerts.map((data) => (
                                                        <tr key={`${data.source}-${data.eventTime}`}>
                                                            <td>
                                                                <Badge bg="secondary">
                                                                    {data.source}
                                                                </Badge>
                                                            </td>
                                                            <td>{formatDate(data.eventTime)}</td>
                                                            <td>{data.overview}</td>
                                                            <td>{data.deviation.toFixed(2)}</td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {data.description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div style={{ cursor: 'help' }}>
                                                                        {data.description}
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {alertType === 'Period' && renderTabs()}
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default Alert; 