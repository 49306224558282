import React, { useState, useEffect, useContext } from "react";
import { AppState } from "../../lib/context/AppProvider";
import "../../styles/page.css";
import SettingsGroup, { renderSatelliteGroup } from "./SettingsGroup";
import SatGroupModal from "./SatGroupModal";


const constsToGroups = (constellations) => {
    if(!constellations) return {};
    const groups = {};
    Object.entries(constellations).forEach(([name, sats]) => groups[name] = {satellites: sats});
    return groups;
}
const groupsToConsts = (groups) => {
    if(!groups) return {};
    const constellations = {};
    Object.entries(groups).forEach(([name, group]) => constellations[name] = group?.satellites);
    return constellations;
}

function ConstellationSettingsGroup({satellites}) {
    const { user, getData, postData } = useContext(AppState);
    const [blueConstellations, setBlueConstellations] = useState({});
    const [redConstellations, setRedConstellations] = useState({});
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await getData("user-details");

                // Set constellations from user details
                if (userData?.Constellations) {
                    setBlueConstellations(constsToGroups(userData.Constellations.blues));
                    setRedConstellations(constsToGroups(userData.Constellations.reds));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if(!user.isGuest) {
            fetchData();
        }
    }, [getData, user]);

    const saveConstellations = async (newBlues, newReds) => {
        try {
            const payload = {
                blues: groupsToConsts(newBlues),
                reds: groupsToConsts(newReds)
            };

            await postData("user-constellations", payload, "PATCH");
        } catch (error) {
            console.error("Failed to update constellations:", error);
            throw error;
        }
    };

    const updateConstellations = async (updatedConstellations, type) => {
        let newBlues = blueConstellations;
        let newReds = redConstellations;

        if (type === "blue") {
            setBlueConstellations(updatedConstellations);
            newBlues = updatedConstellations;
        } else {
            setRedConstellations(updatedConstellations);
            newReds = updatedConstellations;
        }

        await saveConstellations(newBlues, newReds);
    }

    const renderConstellationGroup = (editButtonVariant) => 
        (name, group, handlers) => 
            renderSatelliteGroup(name, group, handlers, satellites, editButtonVariant);

    return <>
        <div className="settings-subsection">
            <SettingsGroup 
                groupType="constellation"
                title={<><span className="text-primary">Blue</span> Team</>}
                description={"Satellites on the blue team will be monitored by Windu for threats against them."}
                testid="blue-constellations"
                typeText={"Blue Constellation"}
                buttonVariant="primary"
                enabled={!user.isGuest}
                satellites={satellites}
                groups={blueConstellations}
                setGroups={(newGroups) => setBlueConstellations(newGroups)}
                saveGroups={(newGroups) => updateConstellations(newGroups, "blue")}
                modalType={SatGroupModal}
                modalProps={{satellites: satellites}}
                renderGroup={renderConstellationGroup("outline-primary")}
            />
        </div>
        <hr style={{ borderColor: "#495057" }}/>
        <div className="settings-subsection mt-4">
            <SettingsGroup 
                groupType="constellation"
                title={<><span className="text-danger">Red</span> Team</>}
                description={"Satellites on the red team will be monitored by Windu for maneuvers, photometry, and other pattern of life changes."}
                testid="red-constellations"
                typeText={"Red Constellation"}
                buttonVariant="danger"
                enabled={!user.isGuest}
                satellites={satellites}
                groups={redConstellations}
                setGroups={(newGroups) => setRedConstellations(newGroups)}
                saveGroups={(newGroups) => updateConstellations(newGroups, "red")}
                modalType={SatGroupModal}
                modalProps={{satellites: satellites}}
                renderGroup={renderConstellationGroup("outline-danger")}
            />
        </div>
    </>;
}

export default ConstellationSettingsGroup;
