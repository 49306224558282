import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../../styles/page.css";
import SatGroupModal from "./SatGroupModal";

export const ProfileAlpabeticalSort = ([n1,s1],[n2,s2]) => n1.localeCompare(n2);

export const renderSatelliteGroup = (
    name, group, {handleEditGroup, handleDeleteGroup, handleDuplicateGroup},
    satellites, editButtonVariant="outline-primary"
) => {
    const sats = group?.satellites;
    if(!sats) return null;
    return (
        <div 
            key={name}
            className="p-3 mb-2 rounded"
            style={{ backgroundColor: "#1a1d20" }}
        >
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                    <h6 className="mb-1 text-white">{name}</h6>
                    <small className="text-light">
                        {sats.length} satellites
                    </small>
                </div>
                <div>
                    <Button
                        variant={editButtonVariant}
                        size="sm"
                        className="me-2"
                        onClick={() => handleEditGroup(name)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handleDeleteGroup(name)}
                    >
                        Delete
                    </Button>
                </div>
            </div>
            <div 
                className="satellite-list" 
                style={{ 
                    maxHeight: "100px", 
                    overflowY: "auto",
                    backgroundColor: "#212529",
                    borderRadius: "4px",
                    padding: "8px"
                }}
            >
                {sats.map(satId => {
                    const satellite = satellites.find(s => s.id === satId);
                    return (
                        <div key={satId} className="small text-white ps-2">
                            {satellite ? `${satId} - ${satellite.name}` : satId}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

function SettingsGroup({
    groupType="satellite group", 
    title, description, testid, typeText,
    buttonVariant="primary", 
    enabled=true,
    renderGroup,
    groups, setGroups, saveGroups,
    modalType=SatGroupModal,
    modalProps={},
}) {
    const [showModal, setShowModal] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    const updateGroupState = (group, existingName = null) => {
        let updatedGroups;

        // Remove old group if name changed
        updatedGroups = Object.fromEntries(
            Object.entries(groups)
                .filter(([key]) => key !== existingName)
        );
        updatedGroups[group.name] = group;

        setGroups(updatedGroups);
        return updatedGroups;
    };

    const handleSaveGroup = async (group) => {
        try {
            const existingName = editingGroup?.name;
            const newGroups = updateGroupState(
                group, 
                existingName
            );

            await saveGroups(newGroups);
            setShowModal(false);
            setEditingGroup(null);
        } catch (error) {
            console.error("Failed to save satellite group:", error);
            // Revert state changes if update failed
            setGroups(groups);
        }
    };

    const handleDeleteConfirmation = async () => {
        if (!deleteConfirmation) return;

        const name = deleteConfirmation;
        try {
            const newGroups = Object.fromEntries(
                Object.entries(groups)
                    .filter(([key]) => key !== name)
            );
            setGroups(newGroups);

            await saveGroups(newGroups);
            setDeleteConfirmation(null);
        } catch (error) {
            console.error("Failed to delete group:", error);
            // Revert state changes if update failed
            setGroups(groups);
        }
    };

    const handleDeleteGroup = (name) => {
        setDeleteConfirmation(name);
    };

    const handleShowModal = () => {
        setEditingGroup(null);  // Ensure we're not in edit mode
        setShowModal(true);
    };

    const handleEditGroup = (name) => {
        const group = groups[name];
            
        setEditingGroup({
            ...group,
            name,
        });
        setShowModal(true);
    };

    const handleDuplicateGroup = async (name) => {
        const group = groups[name];
        const dupe = {...group};
        const updatedGroups = {...groups};
        updatedGroups[name + " Copy"] = dupe;
        setGroups(updatedGroups);
        await saveGroups(updatedGroups);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingGroup(null);
    };

    const renderGroups = (groups, typeText) => (
        <div className="mt-3">
            {groups && Object.entries(groups).sort(ProfileAlpabeticalSort).map(([name, group]) => (
                renderGroup(name, group, {handleEditGroup, handleDeleteGroup, handleDuplicateGroup})
            ))}
            {(!groups || Object.keys(groups).length === 0) && (
                <div className="text-light text-center p-3">
                    No {typeText.toLowerCase()}s defined
                </div>
            )}
        </div>
    );

    const SpecificModal = modalType; // needs to be uppercase to be recognized as a component

    const props = {
        show: showModal,
        onHide: handleCloseModal,
        typeText: typeText,
        buttonVariant: buttonVariant,
        onSave: handleSaveGroup,
        editMode: !!editingGroup,
        existingGroup: editingGroup ?? null,
        ...modalProps
    };

    return (  
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h6 className="settings-subsection-title">
                    {title}
                </h6>
                <Button 
                    variant={buttonVariant}
                    onClick={() => handleShowModal()}
                    className="mb-3"
                    disabled={!enabled}
                >
                    + Add {typeText}
                </Button>
            </div>
            <p>{description}</p>
            <div className="mt-3" data-testid={testid}>
                {renderGroups(groups, typeText)}
            </div>
            
            {showModal && (
                <SpecificModal
                    {...props}
                />
            )}

            <Modal 
                show={!!deleteConfirmation} 
                onHide={() => setDeleteConfirmation(null)}
                size="sm"
            >
                <Modal.Header closeButton className="bg-dark text-white">
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-white">
                    Are you sure you want to delete the {groupType} 
                    <strong> {deleteConfirmation}</strong>?
                </Modal.Body>
                <Modal.Footer className="bg-dark text-white">
                    <Button 
                        variant="secondary" 
                        onClick={() => setDeleteConfirmation(null)}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="danger"
                        onClick={handleDeleteConfirmation}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SettingsGroup;
