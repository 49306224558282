import React, { useContext } from 'react';
import { DashboardState } from '../lib/context/DashboardProvider';
import SpaceCockpitDockContainer from '../components/SpaceCockpitDockContainer';
import Header from '../components/Header';

const Spacefana = () => {

  const {
    dashIndex,
    currentDashboardLink,
    currentSpaceCockpitPosition,
    setSpaceCockpitPosition
  } = useContext(DashboardState);

  const spacefanaFrame = (
    <iframe 
      title="Spacefana"
      src={currentDashboardLink}
      style={{
        width: '100%',
        height: '100%',
        border: 'none'
      }}
      allow='geolocation'
    ></iframe>
  );

  return (
    <div data-testid="home-container" id="home-container">
      <Header height={56} className="pb-5" showProfiles={true} />
      <div id="dashboard-container" style={{
        width: '100%',
        height: 'calc(100vh - 56px - 12px)',
        backgroundColor: "rgb(17, 18, 23)",
      }}>
        <SpaceCockpitDockContainer id={`spacefana-${dashIndex}`} 
            currentSpaceCockpitPosition={currentSpaceCockpitPosition}
            setSpaceCockpitPosition={setSpaceCockpitPosition}
            sidePadding={false}>
          {spacefanaFrame}
        </SpaceCockpitDockContainer>
      </div>
    </div>
  );
};

export default Spacefana;
