import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ModalSearchSection from "./ModalSearchSection";
import GroupModal from "./GroupModal";

export const satFilterFunc = (satellites, selectedSatellites, searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
        return selectedSatellites ? selectedSatellites.map(id => {
            const sat = satellites.find(s => s.id === id);
            return sat || { id, name: id };
        }) : [];
    }

    // Check if the search term is a CSV list of NoradIDs
    if (searchTerm.includes(',')) {
        const noradIds = searchTerm.split(',').map(id => id.trim());
        return satellites.filter(sat => noradIds.includes(sat.id));
    }

    // Regular search by name or ID
    const searchTermLower = searchTerm.toLowerCase();
    return satellites.filter(sat => 
        sat.id.toLowerCase().includes(searchTermLower) || 
        sat.name.toLowerCase().includes(searchTermLower)
    );
};

const SatGroupModal = ({ 
    show, 
    onHide, 
    typeText, 
    buttonVariant = "primary",
    satellites,
    onSave,
    editMode = false,
    existingGroup = { name: "", satellites: [] },
}) => {
    const [selectedSatellites, setSelectedSatellites] = useState(existingGroup?.satellites ?? []);

    const makeGroup = useMemo(() => (groupName) => {
        if(selectedSatellites?.length > 0) {
            return { name: groupName, satellites: selectedSatellites };
        }
        return null;
    }, [selectedSatellites]);

    // Reset state when modal opens/closes or when editMode changes
    useEffect(() => {
        if (show) {
            if (editMode) {
                setSelectedSatellites(existingGroup.satellites);
            } else {
                setSelectedSatellites([]);
            }
        }
    }, [show, editMode, existingGroup]);

    return (
        <GroupModal 
            show={show}
            onHide={onHide}
            typeText={typeText}
            buttonVariant={buttonVariant}
            onSave={onSave}
            editMode={editMode}
            existingGroup={existingGroup}
            makeGroup={makeGroup}
            modalBody={
                <ModalSearchSection 
                    itemOptions={satellites} 
                    selectedItems={selectedSatellites} 
                    setSelectedItems={setSelectedSatellites} 
                    filterFunction={satFilterFunc}
                />
            }
        />
    );
};

SatGroupModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    typeText: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string,
    satellites: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    onSave: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    existingGroup: PropTypes.shape({
        name: PropTypes.string,
        satellites: PropTypes.arrayOf(PropTypes.string)
    })
};

export default SatGroupModal;