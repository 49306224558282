import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ModalSearchSection from "./ModalSearchSection";
import GroupModal from "./GroupModal";
import { satFilterFunc } from "./SatGroupModal";
import { DASHBOARDS } from "../../lib/context/DashboardProvider";

const generalFilterFunc = (options, searchTerm) => {
    // Check if the search term is a CSV list
    if (searchTerm.includes(',')) {
        const searches = searchTerm.split(',').map(id => id.trim());
        return options.filter((source) => searches.includes(source));
    }

    // Regular search by name or ID
    const searchTermLower = searchTerm.toLowerCase();
    return options.filter((source) => source.toLowerCase().includes(searchTermLower));
}

const dashFilterFunc = (dashes, selectedDashes, searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
        return dashes;
    }

    return generalFilterFunc(dashes, searchTerm);
};


const sourceFilterFunc = (sources, selectedSources, searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
        return sources;
    }

    return generalFilterFunc(sources, searchTerm);
};

const dashes = ["Space Cockpit", ...DASHBOARDS];

const ProfileModal = ({ 
    show, 
    onHide, 
    typeText, 
    buttonVariant = "primary",
    satellites,
    sources,
    onSave,
    editMode = false,
    existingGroup = { name: "", satellites: [], sources: [], dashboards: [] },
}) => {
    const [selectedSatellites, setSelectedSatellites] = useState(existingGroup?.satellites ?? []);
    const [selectedSources, setSelectedSources] = useState(existingGroup?.sources ?? [...sources]);
    const [selectedDashboards, setSelectedDashboards] = useState(existingGroup?.dashboards ?? [...dashes]);
    
    const makeGroup = useMemo(() => (groupName) => {
        const group = { name: groupName };
        group.satellites = selectedSatellites ?? [];
        // use empty source list for All
        group.sources = (selectedSources && selectedSources.length !== sources.length) ? selectedSources : [];

        if(selectedDashboards?.length > 0) {
            group.dashboards = selectedDashboards;
        }
        if(group.satellites.length > 0 || group.sources.length > 0) {
            return group;
        }
        return null;
    }, [selectedSatellites, selectedSources, selectedDashboards, sources]);

    // Reset state when modal opens/closes or when editMode changes
    useEffect(() => {
        if (show) {
            if (editMode) {
                setSelectedSatellites(existingGroup.satellites);
                setSelectedSources(existingGroup.sources ?? [...sources]);
                setSelectedDashboards(existingGroup?.dashboards ?? [...dashes]);
            } else {
                setSelectedSatellites([]);
                setSelectedSources([...sources]);
                setSelectedDashboards([...dashes]);
            }
        }
    }, [show, editMode, existingGroup, sources]);

    return (
        <GroupModal 
            show={show}
            onHide={onHide}
            typeText={typeText}
            buttonVariant={buttonVariant}
            onSave={onSave}
            editMode={editMode}
            existingGroup={existingGroup}
            makeGroup={makeGroup}
            modalBody={
                <>
                    <ModalSearchSection 
                        typeText={"Satellites"}
                        itemOptions={satellites} 
                        selectedItems={selectedSatellites} 
                        setSelectedItems={setSelectedSatellites} 
                        filterFunction={satFilterFunc}
                    />
                    <ModalSearchSection 
                        typeText={"Sources"}
                        itemOptions={sources} 
                        selectedItems={selectedSources} 
                        setSelectedItems={setSelectedSources} 
                        filterFunction={sourceFilterFunc}
                        placeholder={"Search by name..."}
                        columns={["Name"]}
                        idFunc = {(source) => source}
                        columnFunc = {(source) => [source]}
                    />

                    <p>Below you can select what dashboards this profile is enabled for. A profile will not be loaded for dashboards that it is not enabled on.</p>

                    <ModalSearchSection 
                        typeText={"Dashboards"}
                        itemOptions={dashes} 
                        selectedItems={selectedDashboards} 
                        setSelectedItems={setSelectedDashboards} 
                        filterFunction={dashFilterFunc}
                        placeholder={"Search by name..."}
                        columns={["Dashboard"]}
                        idFunc = {(item) => item}
                        columnFunc = {(item) => [item]}
                    />
                </>
            }
        />
    );
};

ProfileModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    typeText: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string,
    satellites: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    onSave: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    existingGroup: PropTypes.shape({
        name: PropTypes.string,
        satellites: PropTypes.arrayOf(PropTypes.string)
    })
};

export default ProfileModal;