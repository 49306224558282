import React, { useContext } from 'react';
import Header from "../components/Header";
import SpaceCockpitFrame from '../components/SpaceCockpitFrame';
import { useLocation } from 'react-router-dom';
import { AppState } from '../lib/context/AppProvider';

function SpaceCockpit() {
  const location = useLocation();
  const initialQueryParams = location.search?.length > 0 ? location.search : null;
  const {selectedProfile} = useContext(AppState);

  return (  
    <div data-testid="home-container" id="home-container">
      <Header
            showSideBarMenu={true}
            showDashboardMenu={false}
            showProfiles={true}
            height={56} 
            className="pb-5"
            isSpaceCockpit={true}
        />
      <SpaceCockpitFrame 
        containerStyle={{
            width: '100%',
            height: 'calc(100vh - 56px - 16px)',
        }} 
        time={initialQueryParams ? null : new Date().toISOString()}
        sats={selectedProfile?.satellites ?? null}
        focus={selectedProfile?.satellites?.[0] ?? null}
        initialQueryParams={initialQueryParams}
      />
    </div>
  );
};

export default SpaceCockpit;
