import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Spacefana from "./pages/Spacefana";
import toastConfig from "./lib/config/toast";
import { AppProvider, AppState } from "./lib/context/AppProvider";
import DashboardProvider from "./lib/context/DashboardProvider";
import UnclassHeader from "./components/UnclassHeader";
import Account from "./pages/Account";
import Chat from "./pages/Chat";
import SpaceCockpit from "./pages/SpaceCockpit";
import './styles/App.css';
import Pair from "./pages/Pair";
import Event from "./pages/Event";
import CommandControl from "./pages/CommandControl";
import Settings from "./pages/Settings";
import Home from "./pages/Home";
import Sam from "./pages/Sam";
import SamApp from './pages/SamApp';
import { getDomainUrl } from "./util/Utils";
import Alert from './pages/Alert';

export const ProtectedRoute = () => {
    const {user, pendingRedirect} = useContext(AppState);
    const location = useLocation();

    if (!user || !user.loggedIn || (!user.isGuest && !user.access_token)) {
        pendingRedirect.current = location?.pathname + location?.search;    
        return <Navigate to={"/"} replace />;
    }
    return <Outlet/>;
};

export const AppRoutes = ({dashboardElement}) => (
    <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoute />}>
            {
                ["/dashboards/*"].map((p) =>
                    (<Route key={p} path={p} element={dashboardElement} />)
                )
            }
            <Route path="/sam" element={<Sam />} />
            <Route path="/samapp/:appId" element={<SamApp />} />
            <Route path="/spacecockpit" element={<SpaceCockpit />} />
            <Route path="/pair" element={<Pair />} />
            <Route path="/c2" element={<CommandControl />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/account" element={<Account />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/home" element={<Home />} />
            <Route path="/event/:notsoid" element={<Event />} />
            <Route path="/event/:type/*" element={<Event />} />
            <Route path="/event/:id" element={<Event />} />
            <Route path="/alert/:id" element={<Alert />} />
            <Route path="*" element={<NotFound />} />
        </Route>
    </Routes>
);

const getSpaceCockpitUrl = (accessToken, scQueryParams=null) => {
    let url = `${getDomainUrl('spacecockpit')}/` + (accessToken ? `?access_token=${accessToken}` : "");
    if (scQueryParams) {
        url += (accessToken && scQueryParams.startsWith("?")) ? ("&" + scQueryParams.slice(1)) : scQueryParams;
    }
    return url;
}

export const SpaceCockpitAnchor = () => {

    const { user, scQueryParams } = useContext(AppState);
    const accessToken = user?.access_token;

    const iframeContainerStyle = {
        width: "50px", 
        height: "50px",
        position: "absolute",
        zIndex: 1000,
        visibility: "hidden"
    };

    const iframeStyle = {
        width: '100%',
        height: '100%',
        border: 'none',
        borderRadius: '8px',
        backgroundColor: '#1a1d20'
    };

    return (
        <div style={iframeContainerStyle} id = "sc-frame-anchor">
            <iframe id = "sc-frame"
                src={getSpaceCockpitUrl(accessToken, scQueryParams)}
                style={iframeStyle}
                title="Space Cockpit"
                aria-label="Space Cockpit Dashboard"
                allow="geolocation; clipboard-read; clipboard-write"
                sandbox="allow-scripts allow-same-origin allow-forms"
            />
        </div>
    );
}

export const AppContents = () => (
    <>
        <ToastContainer {...toastConfig} />
        <SpaceCockpitAnchor />
        <UnclassHeader />
        <AppRoutes dashboardElement={
            <DashboardProvider>
                <Spacefana />
            </DashboardProvider>
        } />
    </>
);

export const AppRouter = ({children}) => (
    <Router basename={"/"} future={{v7_startTransition: true,v7_relativeSplatPath: true}}>
        {children}
    </Router>
);

export const App = () => {
    return (
        <AppRouter>
            <AppProvider>
                <AppContents />
            </AppProvider>
        </AppRouter>
    );
};