import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppState } from './AppProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSCPositionSettings } from './LocalSettings';

export const DashboardState = createContext();

const getDashboards = (access_token) => {
    const isLocalDev = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    const baseUrl = isLocalDev 
        ? "http://localhost:3000/" 
        : (window.location.origin.replace("sbms","spacefana")+"/");

    const accessTokenParam = access_token?.length > 0 ? `var-accesstoken=${access_token}&` : "";

    return [
        {
            name: "GEO Summary",
            link: `${baseUrl}d/5oK_KFK4z/geo-summary?refresh=30s&${accessTokenParam}kiosk`,
        },
        {
            name: "RPO",
            link: `${baseUrl}d/be791bd0-9a37-45d7-b956-029966d4b570/rpo?${accessTokenParam}kiosk`,
        },
        {
            name: "GWAS",
            link: `${baseUrl}d/KPR_hDkVz/geo-waterfall?var-showSun=no&${accessTokenParam}kiosk`,
        },
        {
            name: "Photometry",
            link: `${baseUrl}d/RCHUUtW4z/photometry?${accessTokenParam}kiosk`,
        },
        {
            name: "Photo Multiplot",
            link: `${baseUrl}d/ce5xt8v39mcxsd/photometry-multiplot?${accessTokenParam}kiosk`,
        },
        {
            name: "Monet",
            link: `${baseUrl}d/ae9pzazusvkzke/monetplot?var-accesstoken=${access_token}&kiosk`,
        },
        {
            name: "Elset History",
            link: `${baseUrl}d/PO81TfmVk/elset-history?${accessTokenParam}kiosk`,
        },
        {
            name: "Satellite Threats",
            link: `${baseUrl}d/fdwfc04e39on4f/satellite-threats?${accessTokenParam}kiosk`,
        },
        {
            name: "Launch Threats",
            link: `${baseUrl}d/cdwujo30o8wsgd/asat-threats?${accessTokenParam}kiosk`,
        },
        {
            name: "Longitude Neighborhood",
            link: `${baseUrl}d/ce58nh4hgpypse/longitudeneighborhood?${accessTokenParam}kiosk`,
        },
        {
            name: "Residuals",
            link: `${baseUrl}d/FOBAMEzVz/residuals?${accessTokenParam}kiosk`,
        },
        {
            name: "TRIC",
            link: `${baseUrl}d/ZXiz9jkVk/tric?${accessTokenParam}kiosk`,
        },
        {
            name: "LEO Waterfall",
            link: `${baseUrl}d/aea48nghbugw0b/leo-waterfall?${accessTokenParam}kiosk`
        },
        {
            name: "AltvInc",
            link: `${baseUrl}d/JcXBmud4z/altitudeinclination?${accessTokenParam}kiosk`,
        },
        {
            name: "GEO Map",
            link: `${baseUrl}d/WL1YXSK4k/geo-map?${accessTokenParam}kiosk`,
        },
        {
            name: "Data Status",
            link: `${baseUrl}d/ff16adf2-0eb2-4fb1-a602-380f95b82303/data-status?${accessTokenParam}kiosk`,
        },
        {
            name: "Sensor Map",
            link: `${baseUrl}d/D8fDCYO4k/sensor-map?${accessTokenParam}kiosk`,
        },
        {
            name: "Orbit Determination",
            link: `${baseUrl}d/f3a6255f-40d8-4b9e-8f6a-0c5bfbdc198d/orbit-determination?${accessTokenParam}kiosk`,
        },
        {
            name: "Space Weather",
            link: `${baseUrl}d/rC4UUfW4d/space-weather?${accessTokenParam}kiosk`,
        },
        {
            name: "Launch Profiles",
            link: `${baseUrl}d/aacbb349-7831-4ace-9e1b-91212b360232/launch-profiles?${accessTokenParam}kiosk`,
        },
        {
            name: "Constellation Monitor",
            link: `${baseUrl}d/fdm1ev7wgn9j4b/constellation-monitor?${accessTokenParam}kiosk`,
        },
        {
            name: "Maneuver History",
            link: `${baseUrl}d/feapme7gzwwlcd/maneuver-history?${accessTokenParam}kiosk`,
        },
        {
            name: "Alerts",
            link: `${baseUrl}d/ddrcoqkrlzgn4d/alerts?${accessTokenParam}kiosk`,
        },
        {
            name: "TRIC Manual",
            link: `${baseUrl}d/fa1eefd6-9ca5-438e-bd92-ec04db2365b1/tric-manual?${accessTokenParam}kiosk`,
        }
    ];
}

export const DASHBOARDS = getDashboards("").map(({name}) => name);

export const SpaceCockpitPositions = {
  "Constellation Monitor": {position: "bottom", size: 0.5},
  "Maneuver History": {position: "bottom", size: 0.5},
  "RPO": {position: "right", size: 0.33},
  "Alerts": {position: "right", size: 0.5}
}

const SatnoLimits = {
  "RPO": 1,
  "Photometry": 1,
  "Monet": 1, 
  "Residuals": 1, 
  "Orbit Determination": 1, 
  "Maneuver History": 1, 
}
const SourceLimits = {
  "LEO Waterfall": 1,
}

const encodeDashboardName = (name) => {
  return name.toLowerCase().replace(" ", "-").replace("/", "");
}

export const getDashboardIndex = (dashboards, name) => {
  const encoded = encodeDashboardName(name);
  return dashboards.findIndex(x => encodeDashboardName(x.name) === encoded);
}

const getLink = (url, selectedProfile, dashName, currentQueryParams) => {
  if(selectedProfile && 
      (!selectedProfile.dashboards?.length || selectedProfile.dashboards?.includes(dashName))) {
    
    let sats = selectedProfile.satellites;
    if(dashName in SatnoLimits) {
      sats = sats.slice(0, SatnoLimits[dashName]);
    }
    
    if(sats?.length > 0) {
      url.searchParams.set("var-satnos", sats.join(","));
      url.searchParams.set("var-satno", sats[0]);
      url.searchParams.set("var-satno1", sats[0]);
      if(sats.length >= 2) {
        url.searchParams.set("var-satno2", sats[1]);
      }
    }

    let sources = selectedProfile.sources;
    if(dashName in SourceLimits) {
      sources = sources.slice(0, SourceLimits[dashName]);
    }
    if(sources?.length > 0) {
      url.searchParams.set("var-source", sources.join(","));
    }
  }
  if(currentQueryParams?.length > 0) {
    const params = new URLSearchParams(currentQueryParams);
    params.forEach((v, k) => {
      url.searchParams.set(k, v);
    });
  }
  const urlStr = url.toString();
  return urlStr.replace(/(?<=[&?])kiosk=(?=(&|$))/g, "kiosk"); // url ends up like ?kiosk=&foo=bar when we need ?kiosk&foo=bar;
}

function DashboardProvider({ children }) {

  const { user, selectedProfile } = useContext(AppState);
  const [dashIndex, setDashIndex] = useState(0);

  const dashboards = useMemo(() => getDashboards(user?.access_token), [user?.access_token]);
  
  const location = useLocation();
  const dash = location.pathname.split("/dashboards/")[1];

  if(dash) {
    const index = getDashboardIndex(dashboards, dash);
    if(index >= 0 && dashIndex !== index) {
      setDashIndex(index);
    }
  }

  const [currentQueryParams, setCurrentQueryParams] = useState(null);

  useEffect(() => {
    if(location.search.length > 0) {
      setCurrentQueryParams(location.search);
      window.history.replaceState(null, "Dashboard", location.pathname);
    }
  }, [location]);

  const currentDashboardLink = useMemo(() => {
    const url = new URL(dashboards[dashIndex].link);
    return getLink(url, selectedProfile, dashboards[dashIndex].name, currentQueryParams);
  }, [dashboards, dashIndex, currentQueryParams, selectedProfile]);

  const {
    getSCPosition, setSCPosition
  } = useSCPositionSettings();
  
  const dashboardName = useMemo(() => {
    return dashboards[dashIndex].name;
  }, [dashboards, dashIndex]);

  const currentSpaceCockpitPosition = useMemo(() => getSCPosition(dashboardName), 
    [dashboardName, getSCPosition]);

  const setSpaceCockpitPosition = useCallback((position) => setSCPosition(dashboardName, position), 
    [dashboardName, setSCPosition]);

  const navigate = useNavigate();
  const goToDashboard = useCallback((index) => {
    setCurrentQueryParams(null);
    navigate("/dashboards/" + encodeDashboardName(dashboards[index].name));
  }, [dashboards, navigate]);

  const store = useMemo(() => ({
    dashIndex,
    goToDashboard,
    currentDashboardLink,
    dashboards,
    dashboardName,
    currentSpaceCockpitPosition, setSpaceCockpitPosition
  }), [dashIndex, dashboards, currentDashboardLink, goToDashboard, 
        dashboardName, currentSpaceCockpitPosition, setSpaceCockpitPosition]);
  
  return (  
    <DashboardState.Provider value={store}>
      { children }
    </DashboardState.Provider>
  );
};

export default DashboardProvider;
