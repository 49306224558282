import React, { useContext, useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import {AppState} from "../lib/context/AppProvider";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { FiCopy } from "react-icons/fi";
import { jwtDecode } from "jwt-decode";
import Header from "../components/Header";
import { FaRegAddressCard } from "react-icons/fa";
import { RxUpdate } from "react-icons/rx";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { clearGuestCookie } from "./Login";
import { useNavigate } from "react-router-dom";

export const getLoginLocation = (login) => {
    let loc = "";
    if(login.City) {
        loc += login.City;
    }
    if(login.Region) {
        loc += (loc.length === 0 ? "" : " ") + login.Region;
    }
    if(login.Country) {
        loc += (loc.length === 0 ? "" : ", ") + login.Country;
    }
    return loc.length === 0 ? "Unknown" : loc;
}

export const getRelative = (time) => {
    const login = new Date(time+"Z");
    const now = Date.now();
    
    let mins = Math.floor((now - login) / 1000 / 60);
    const hours = Math.floor(mins / 60);
    mins -= hours * 60;
    
    let timeStr = "";

    if(hours > 0) {
        timeStr += `${hours}h`;
    }
    if(mins > 0) {
        timeStr += (timeStr === "" ? "" : " ") + `${mins}m`;
    }
    return timeStr === "" ? "now" : timeStr;
}

export const logoutUser = async (user, getBaseUrl, navigate, setUser) => {
    if(user.isGuest) {
        clearGuestCookie();
    }
    else {
        const baseUrl = getBaseUrl(false);
        await fetch(`${baseUrl}/logout`, {
            method: 'POST',
            credentials: 'include',
        });
    }

    setUser({
        loggedIn: false,
        uuid: null,
        access_token: null,
        isGuest: false,
    });
    
    navigate("/");
};

export const LoginTable = ({prevLogins}) => {
    return (
        <div className="login-table-container">
            <div className="login-table">
                <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    variant="dark"
                    key={4}
                    style={{textAlign: "center"}}
                >
                    <thead className = "login-table-head">
                        <tr>
                            <th style={{width: "10%"}}></th>
                            <th style={{width: "30%"}}>IP</th>
                            <th style={{width: "30%"}}>Location</th>
                            <th style={{width: "30%"}}>Time Ago</th>
                        </tr>
                    </thead>
                    <tbody style={{overflowY: "scroll"}}>
                        {prevLogins?.logins && prevLogins.logins.map((login, i) => {
                            return (
                                <tr
                                    key={login.Id}
                                    className="table-row"
                                >
                                    <td className="login-table-cell horizontal-scroll-overflow hidden-scrollbar"
                                        style={{color: login.Success !== 1 ? "red":"white"}}>
                                            {login.Success === 1 ? "✓" : "✕"}
                                    </td>
                                    <td className="login-table-cell horizontal-scroll-overflow hidden-scrollbar"
                                        style={{color: login.IsDifferentIP ? "#FFC107" : "white", maxWidth: "120px"}}>
                                            {login.IpAddress}
                                    </td>
                                    <td className="login-table-cell hidden-scrollbar">{getLoginLocation(login)}</td>
                                    <td className="login-table-cell horizontal-scroll-overflow hidden-scrollbar">{getRelative(login.Timestamp)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
        );
};

function Account() {
    const {user, setUser, getBaseUrl, getData, postLogin } = useContext(AppState);
    const [copied, setCopied] = useState(false);
    const [timeUntilExpiry, setTimeUntilExpiry] = useState('');
    const [prevLogins, setPrevLogins] = useState([]);
    const [userDetails, setUserDetails] = useState({
        Providers: [],
        Username: '',
        AccessToken: '',
        CacId: ''
    });
    const [showModal, setShowModal] = useState(false);
    const [udlUsername, setUdlUsername] = useState('');
    const [udlPassword, setUdlPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const navigate = useNavigate();

    // On Load get providers and other data
    useEffect(() => {
        async function fetchData() {
            const [loginData, userData] = await Promise.all([
                getData("sbms/previousLogins"),
                getData("user-details")
            ]);
            setPrevLogins(loginData);
            setUserDetails(userData);
        }

        if(user.isGuest) {
            setUserDetails({
                Providers: ["18th SPCS"],
                Username: 'Guest',
                AccessToken: '',
                CacId: ''
            });
        }
        else {
            fetchData();
        }
    }, [user, getData]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                if (!user.isGuest) {
                    const details = await getData("user-details");
                    if (details) {
                        setUserDetails(details);
                    }
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, [getData, user.isGuest]);

    const calculateTimeUntilExpiry = useCallback(() => {
        try {
            if (!userDetails.AccessToken) return '';
            const decoded = jwtDecode(userDetails.AccessToken);
            if (!decoded.exp) return '';

            const expiryTime = decoded.exp * 1000;
            const now = Date.now();
            const timeLeft = expiryTime - now;

            if (timeLeft <= 0) return 'Expired';

            const hours = Math.floor(timeLeft / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            return `${hours}h ${minutes}m ${seconds}s`;
        } catch (error) {
            return '(Invalid JWT)';
        }
    }, [userDetails.AccessToken]);

    useEffect(() => {
        setTimeUntilExpiry(calculateTimeUntilExpiry());

        const interval = setInterval(() => {
            setTimeUntilExpiry(calculateTimeUntilExpiry());
        }, 1000);

        return () => clearInterval(interval);
    }, [calculateTimeUntilExpiry]);

    const handleCopy = () => {
        navigator.clipboard.writeText(userDetails.AccessToken);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleCACLink = async () => {
        try {
            const baseUrl = getBaseUrl(true);
            const response = await fetch(`${baseUrl}/login`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!response.ok) {
                throw new Error('CAC linking failed');
            }

            const data = await response.json();
            setUser(prev => ({
                ...prev,
                CacId: data.CacId
            }));

            toast.success('CAC successfully linked!');
        } catch (error) {
            toast.error('Failed to link CAC: ' + error.message);
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleConfirm = async (e) => {
        e.preventDefault();
        
        if (!udlUsername.trim() || !udlPassword.trim()) {
            setValidated(true);
            return;
        }

        try {
            const loginResult = await postLogin(udlUsername, udlPassword);
            handleCloseModal();
            await toast.promise(Promise.resolve(loginResult), {
                loading: 'Fetching your UDL providers...',
                success: 'UDL providers updated successfully!',
                error: 'Failed to update UDL providers. Please try again.'
            });
            const updatedUserDetails = await getData("user-details");
            setUserDetails(updatedUserDetails);
            setValidated(false);
            setUdlUsername('');
            setUdlPassword('');
        } catch (error) {
            console.error('Failed to update UDL providers:', error);
        }
    };

    const updateUdlProviders = async () => {
        handleShowModal();
    };

  return (  
    <>
        <Header
            showSideBarMenu={true}
            showDashboardMenu={false}
            height={56} 
            className="pb-5"
        />
        <div id="page-background">
            <section className="settings-section">
                <h4 className="settings-section-title">Your Account</h4>
                <hr style={{ borderColor: '#495057' }}/>
                
                {!user.isGuest && (<>
                    <div className="settings-subsection">
                        <h6 className="settings-subsection-title">Access Token</h6>
                        <div className="d-flex">
                            <Form.Control 
                                type="text" 
                                className="me-2"
                                style={{maxWidth: "500px"}}
                                value={userDetails.AccessToken || ""}
                                readOnly
                            />
                            <Button
                                variant={copied ? "secondary" : "warning"}
                                onClick={handleCopy}
                                aria-label="Copy access token"
                            >
                                {copied ? 'Copied!' : <FiCopy />}
                            </Button>
                        </div>
                        {timeUntilExpiry && (
                            <div className="small">
                                Expires in: <span className="text-warning">{timeUntilExpiry}</span>
                            </div>
                        )}
                        
                    </div>
                    <div className="settings-subsection mt-4">
                        <h6 className="settings-subsection-title">CAC/PKI Authentication</h6>
                        {!userDetails.CacId || userDetails.CacId === "" ? (
                            <>
                                <p className="white small">
                                    Link your Common Access Card (CAC) to enable secure login and identity verification.
                                </p>
                            
                                <Button
                                    variant="outline-warning"
                                    className="btn-accounts"
                                    onClick={handleCACLink}
                                >
                                <FaRegAddressCard /> Link your CAC/PKI Card
                            </Button>
                            </>
                        ) : (
                            <p className="white small text-break" style={{ 
                                wordWrap: "break-word",
                                maxWidth: "100%",
                                overflowWrap: "break-word"
                            }}>
                                {userDetails.CacId}
                            </p>
                        )}
                    </div>
                </>)}
                <div className="settings-subsection mt-4">
                    <h6 className="settings-subsection-title">UDL Providers</h6>
                        <p className="white small text-break" style={{ 
                            wordWrap: "break-word",
                            maxWidth: "100%",
                            overflowWrap: "break-word"
                        }}>
                            <b>Username:</b> <span data-testid="username-value">{userDetails.Username}</span> <br/>
                            <b>Providers:</b> {userDetails && userDetails.Providers && userDetails.Providers.length > 0 ? userDetails.Providers.join(", ") : "None"} <br/>
                            {!user.isGuest && (
                                <Button
                                    variant="outline-warning"
                                    className="btn-accounts"
                                    onClick={updateUdlProviders}
                                >
                                    <RxUpdate /> Update UDL Providers
                                </Button>
                            )}
                        </p>
                </div>
            </section>

            {!user.isGuest && (
                <section className="settings-section">
                    <h4 className="settings-section-title">Login History (24h)</h4>
                    <hr style={{ borderColor: '#495057' }}/>
                    
                    <p className="current-ip mb-3" data-testid="current-ip-section">
                        Current IP: <span className="text-warning">{prevLogins?.ip || "Unknown"}</span>
                    </p>
                    
                    <LoginTable prevLogins={prevLogins} />
                </section>
            )}

            <Button
                variant="outline-warning"
                className="btn-accounts mb-5"
                onClick={() => logoutUser(user, getBaseUrl, navigate, setUser)}
            >
                Log out
            </Button>
        </div>
        
        <Modal show={showModal} onHide={handleCloseModal} >
            <Modal.Header closeButton className="bg-dark text-white">
                <Modal.Title>Update UDL Providers</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                <Form noValidate validated={validated} onSubmit={handleConfirm} role="form">
                    <Form.Group controlId="formUdlUsername">
                        <Form.Label>UDL Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your UDL username"
                            required
                            value={udlUsername}
                            onChange={(e) => setUdlUsername(e.target.value)}
                            className="bg-dark text-white"
                            isInvalid={validated && !udlUsername.trim()}
                            aria-label="UDL Username"
                        />
                        <Form.Control.Feedback type="invalid">
                            Username is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formUdlPassword" className="mt-3">
                        <Form.Label>UDL Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter your UDL password"
                            required
                            value={udlPassword}
                            onChange={(e) => setUdlPassword(e.target.value)}
                            className="bg-dark text-white"
                            isInvalid={validated && !udlPassword.trim()}
                            aria-label="UDL Password"
                        />
                        <Form.Control.Feedback type="invalid">
                            Password is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="mt-3 d-flex justify-content-end">
                        <Button variant="secondary" onClick={handleCloseModal} className="me-2">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Confirm
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    </>
  );
};

export default Account;
