import React, { useEffect, useState, useContext } from 'react';
import Header from "../components/Header";
import { useNavigate } from 'react-router-dom';
import { AppState } from "../lib/context/AppProvider";
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import LicenseAgreementModal from "../components/LicenseAgreementModal";
import PurchaseConfirmationModal from "../components/PurchaseConfirmationModal";

// Extract card image component
const AppPreviewImage = ({ previewImage, title }) => (
    <div className="bg-dark position-relative" style={{ paddingTop: '56.25%' }}>
        {previewImage ? (
            <img 
                src={`data:image/png;base64,${previewImage}`}
                alt={title}
                style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    padding: '0'
                }}
            />
        ) : (
            <div 
                className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                style={{ backgroundColor: '#2a2a2a' }}
            >
                <span className="text-white-50">Preview</span>
            </div>
        )}
    </div>
);

// Extract high-side compatibility badge
const HighSideCompatibilityBadge = () => (
    <div className="d-flex align-items-center mb-2">
        <svg className="me-1" width="16" height="16" fill="none" stroke="white" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
        <small className="text-white">High-Side Compatible</small>
    </div>
);

// Extract app action buttons
const AppActions = ({ app, user, onLaunch, onShowLicense, openDropdownId, onDropdownToggle, onDeleteClick }) => {
    const getButtonText = (app, user) => {
        if (user.isGuest) {
            return 'Must be logged in';
        }
        if (app.isPurchased) {
            return 'Purchased';
        }
        return app.Price === 0 ? 'Get' : `${app.Price} credits`;
    };

    const getButtonStyle = (app, user) => {
        if (app.isPurchased) {
            return '#8B7355';
        }
        if (user.isGuest) {
            return '#666666';
        }
        return '#f5bc59';
    };

    const handlePurchaseClick = (e) => {
        if (!user.isGuest && !app.isPurchased) {
            e.preventDefault();
            e.stopPropagation();
            onShowLicense(app);
        }
    };

    const handleDeleteClick = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        onDeleteClick(app);
    };

    const handleDropdownToggle = (e, appId) => {
        e.preventDefault();
        e.stopPropagation();
        if (app.isPurchased) {
            onDropdownToggle(e, app.Id);
        }
    };

    const buttonColor = getButtonStyle(app, user);

    return (
        <div className="d-flex gap-1">
            <Button
                variant="warning"
                onClick={handlePurchaseClick}
                style={{ 
                    backgroundColor: buttonColor,
                    borderColor: buttonColor,
                    flex: 1,
                    cursor: user.isGuest || app.isPurchased ? 'not-allowed' : 'pointer',
                    opacity: user.isGuest ? 0.8 : 1
                }}
                disabled={user.isGuest || app.isPurchased}
            >
                {getButtonText(app, user)}
            </Button>
            {!user.isGuest && (
                <div className="dropdown" style={{ position: 'relative' }}>
                    <Button
                        variant="warning"
                        className="dropdown-toggle dropdown-toggle-split"
                        onClick={handleDropdownToggle}
                        style={{ 
                            backgroundColor: app.isPurchased ? '#f5bc59' : '#666666',
                            borderColor: app.isPurchased ? '#f5bc59' : '#666666',
                            padding: '0 8px',
                            height: '38px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: app.isPurchased ? 'pointer' : 'not-allowed',
                            opacity: app.isPurchased ? 1 : 0.65
                        }}
                        disabled={!app.isPurchased}
                    >
                        <span className="visually-hidden">Toggle Dropdown</span>
                    </Button>
                    <ul className={`dropdown-menu dropdown-menu-end ${openDropdownId === app.Id ? 'show' : ''}`}
                        style={{ 
                            backgroundColor: '#2a2a2a',
                            pointerEvents: app.isPurchased ? 'auto' : 'none',
                            position: 'absolute',
                            top: '100%',
                            right: 0,
                            marginTop: '2px',
                            maxHeight: '300px',
                            overflowY: 'auto',
                            minWidth: '200px'
                        }}
                    >
                        <li>
                            <button 
                                className="dropdown-item"
                                onClick={handleDeleteClick}
                                style={{ 
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '8px 16px',
                                    color: '#ff4444'
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#3a3a3a';
                                    e.currentTarget.style.color = '#ff6666';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                    e.currentTarget.style.color = '#ff4444';
                                }}
                            >
                                Delete application
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

// Extract app card component
const AppCard = ({ app, user, onLaunch, onShowLicense, openDropdownId, onDropdownToggle, onDeleteClick }) => {
    const handleKeyDown = (e) => {
        if (!user.isGuest && (e.key === 'Enter' || e.key === ' ')) {
            onLaunch(app.Id);
        }
    };

    return (
        <Card 
            className="h-100" 
            style={{ 
                backgroundColor: '#474747',
                cursor: user.isGuest ? 'not-allowed' : 'pointer',
                transition: 'transform 0.2s',
                opacity: user.isGuest ? 0.8 : 1
            }}
            onClick={() => !user.isGuest && onLaunch(app.Id)}
            role="button"
            data-testid={`app-card-${app.Id}`}
            tabIndex={user.isGuest ? -1 : 0}
            onKeyDown={handleKeyDown}
        >
            <AppPreviewImage previewImage={app.PreviewImage} title={app.Title} />
            <Card.Body className="p-3">
                <Card.Title className="text-white text-truncate mb-1" style={{ fontSize: '1.1rem' }}>
                    {app.Title}
                </Card.Title>
                {app.Company && (
                    <div className="text-truncate mb-1" style={{ fontSize: '0.8rem', color: '#f5bc59' }}>
                        {app.Company}
                    </div>
                )}
                
                <div style={{ height: '80px' }}>
                    {Boolean(app.HighSideCompatible) && <HighSideCompatibilityBadge />}
                    <Card.Text className="text-white" style={{ 
                        fontSize: '0.8rem',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        margin: 0
                    }}>
                        {app.Description}
                    </Card.Text>
                </div>
                <AppActions 
                    app={app}
                    user={user}
                    onLaunch={onLaunch}
                    onShowLicense={onShowLicense}
                    openDropdownId={openDropdownId}
                    onDropdownToggle={onDropdownToggle}
                    onDeleteClick={onDeleteClick}
                />
            </Card.Body>
        </Card>
    );
};

function Sam() {
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [showLicenseModal, setShowLicenseModal] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [appToDelete, setAppToDelete] = useState(null);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const navigate = useNavigate();
    const { getData, postData, user } = useContext(AppState);

    useEffect(() => {
        const fetchAppsAndStatus = async () => {
            if (user.isGuest) {
                // For guest users, show 9 placeholder app cards
                const placeholderApps = Array(9).fill(null).map((_, index) => ({
                    Id: `placeholder-${index}`,
                    Title: "App Preview",
                    Company: "Login to View",
                    Description: "Login to view app details and purchase.",
                    Price: 0,
                    PreviewImage: "",
                    HighSideCompatible: false,
                    isPurchased: false
                }));
                setApps(placeholderApps);
                return;
            }

            try {
                // For logged in users, fetch both apps and user-apps
                const [appsData, userApps] = await Promise.all([
                    getData('apps'),
                    getData('user-apps')
                ]);

                if (!appsData) {
                    setApps([]);
                    return;
                }

                // Set apps with purchase status
                const appsWithStatus = appsData.map(app => {
                    const userApp = userApps?.find(userApp => userApp.AppId === app.Id);
                    return {
                        ...app,
                        isPurchased: Boolean(userApp)
                    };
                });
                
                setApps(appsWithStatus);
            } catch (error) {
                console.error("Error fetching apps:", error);
                setApps([]);
            }
        };
        
        fetchAppsAndStatus();
    }, [getData, user.isGuest]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openDropdownId && !event.target.closest('.dropdown')) {
                setOpenDropdownId(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openDropdownId]);

    const handleLaunch = (appId) => {
        if (user.isGuest) {
            return; // Prevent launching for guest users
        }
        navigate(`/samapp/${appId}`);
    };

    const handleLicenseAccept = () => {
        setShowLicenseModal(false);
        setShowPurchaseModal(true);
    };

    const handlePurchaseHide = () => {
        setShowPurchaseModal(false);
        setSelectedApp(null);
    };

    const handlePurchaseSuccess = async () => {
        try {
            // First deduct credits if it's a paid app
            if (selectedApp?.Price > 0) {
                const updatedCredits = await postData('user-credits', { credits: selectedApp.Price });
                if (!updatedCredits) {
                    throw new Error('Failed to update credits');
                }
            }
            
            // Then make the purchase
            const purchaseResult = await postData(`apps/${selectedApp.Id}/purchase`);
            if (purchaseResult?.ok) {
                setApps(prevApps => 
                    prevApps.map(app => 
                        app.Id === selectedApp.Id 
                            ? { ...app, isPurchased: true }
                            : app
                    )
                );
                setShowPurchaseModal(false);
            } else {
                throw new Error('Purchase failed');
            }
        } catch (error) {
            console.error('Error during purchase:', error);
            // Let the PurchaseConfirmationModal handle the error display
            throw error;
        }
    };

    const handleDeleteClick = (app) => {
        setAppToDelete(app);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await postData(`user-apps/${appToDelete.Id}`, {}, 'DELETE');
            
            // Update the apps list to reflect the deletion
            setApps(prevApps => 
                prevApps.map(app => 
                    app.Id === appToDelete.Id 
                        ? { ...app, isPurchased: false }
                        : app
                )
            );
            
            console.log('Successfully deleted app:', appToDelete?.Title);
        } catch (error) {
            console.error('Error deleting app:', error);
        } finally {
            setShowDeleteModal(false);
            setAppToDelete(null);
        }
    };

    const handleDropdownToggle = (e, appId) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenDropdownId(openDropdownId === appId ? null : appId);
    };

    return (  
        <div className="min-vh-100 bg-dark d-flex flex-column">
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
                showCredits={true}
            />
            <Container fluid className="py-4 flex-grow-1 overflow-auto" style={{ maxWidth: "1800px", height: "calc(100vh - 56px)" }}>
                <Row xs={2} md={3} lg={4} xl={6} className="g-3">
                    {apps?.map((app) => (
                        <Col key={`${app.Id}-${app.Title}`}>
                            <AppCard
                                app={app}
                                user={user}
                                onLaunch={handleLaunch}
                                onShowLicense={(app) => {
                                    setSelectedApp(app);
                                    setShowLicenseModal(true);
                                }}
                                openDropdownId={openDropdownId}
                                onDropdownToggle={handleDropdownToggle}
                                onDeleteClick={handleDeleteClick}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>

            <LicenseAgreementModal
                show={showLicenseModal}
                onHide={() => {
                    setShowLicenseModal(false);
                    setSelectedApp(null);
                }}
                onAccept={handleLicenseAccept}
                appTitle={selectedApp?.Title}
            />

            <PurchaseConfirmationModal
                show={showPurchaseModal}
                onHide={handlePurchaseHide}
                onConfirm={handlePurchaseSuccess}
                appTitle={selectedApp?.Title}
                appId={selectedApp?.Id}
                appPrice={selectedApp?.Price}
            />

            <Modal
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false);
                    setAppToDelete(null);
                }}
                centered
                backdrop="static"
                style={{ color: 'white' }}
            >
                <Modal.Header closeButton style={{ backgroundColor: '#2a2a2a', borderBottom: '1px solid #3a3a3a' }}>
                    <Modal.Title style={{ color: 'white' }}>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#2a2a2a' }}>
                    Are you sure you want to delete {appToDelete?.Title}? You may lose access to previous in-app purchases.
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#2a2a2a', borderTop: '1px solid #3a3a3a' }}>
                    <Button 
                        variant="secondary" 
                        onClick={() => {
                            setShowDeleteModal(false);
                            setAppToDelete(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="danger" 
                        onClick={handleDeleteConfirm}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Sam;
