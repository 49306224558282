import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AppState } from '../lib/context/AppProvider';

const PurchaseConfirmationModal = ({ show, onHide, onConfirm, appTitle = '', appId, appPrice = 0 }) => {
    const { getData, user } = useContext(AppState);
    const [creditBalance, setCreditBalance] = useState(0);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCredits = async () => {
            if (!show || !user || user.isGuest) {
                return;
            }

            try {
                const credits = await getData('user-credits');
                setCreditBalance(credits?.Credits ?? 0);
            } catch (err) {
                console.error('Error fetching user credits:', err);
                setCreditBalance(0);
                setError('Failed to fetch credit balance');
            }
        };

        void fetchCredits();
    }, [show, getData, user]);

    const handleConfirm = async () => {
        if (!appId) {
            setError('Invalid app ID');
            return;
        }

        if (creditBalance < appPrice) {
            setError('Insufficient credits');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            await onConfirm();
        } catch (err) {
            console.error('Error during purchase:', err);
            setError(err.message || 'An error occurred during purchase. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const remainingCredits = creditBalance - appPrice;

    return (
        <Modal
            show={Boolean(show)}
            onHide={onHide}
            centered
            size="md"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            aria-label={`Purchase '${appTitle}' Application`}
        >
            <Modal.Header className="bg-dark text-white border-0">
                <Modal.Title style={{ fontSize: '1.5rem', width: '100%' }}>
                    Purchase &apos;{appTitle}&apos; Application
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white py-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <span>Current Credit Balance</span>
                    <span>{creditBalance} credits</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <span>Application Price</span>
                    <span>{appPrice} credits</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3" style={{ borderTop: '1px solid rgba(255,255,255,0.2)', paddingTop: '1rem' }}>
                    <span>Remaining Credits After Purchase</span>
                    <span>{remainingCredits} credits</span>
                </div>
                {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                        {error}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="bg-dark border-0 d-flex justify-content-between px-3 pb-3">
                <Button 
                    variant="danger" 
                    onClick={onHide}
                    disabled={isLoading}
                    style={{
                        width: '120px',
                        backgroundColor: '#ff6b6b',
                        borderColor: '#ff6b6b'
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    variant="success"
                    onClick={handleConfirm}
                    disabled={isLoading || creditBalance < appPrice}
                    style={{
                        width: '120px',
                        backgroundColor: '#4CAF50',
                        borderColor: '#4CAF50'
                    }}
                >
                    {isLoading ? 'Processing...' : 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PurchaseConfirmationModal; 