import React, { useEffect, useRef, useState } from 'react';
import SpaceCockpitFrame from './SpaceCockpitFrame';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { FaLongArrowAltDown, FaLongArrowAltLeft, FaLongArrowAltRight, FaLongArrowAltUp } from 'react-icons/fa';
import { BiSolidDockBottom, BiSolidDockLeft, BiSolidDockRight, BiSolidDockTop } from 'react-icons/bi';

export const getSCPos = (currentSpaceCockpitPosition) => {
  return currentSpaceCockpitPosition?.position?.toLowerCase();
};

const isVerticalLayout = (currentSpaceCockpitPosition) => {
  const pos = getSCPos(currentSpaceCockpitPosition);
  return pos === "top" || pos === "bottom";
};

const CollapseButton = ({panelRef, panelMinSize, panelDefaultSize, currentSpaceCockpitPosition}) => {
  const pos = getSCPos(currentSpaceCockpitPosition);
  const isVertical = isVerticalLayout(currentSpaceCockpitPosition);

  const panel = panelRef.current;
  const isCollapsed = panel?.isCollapsed();
  
  const cTitle = isCollapsed ? "Expand" : "Collapse";

  const verticalIcon = isCollapsed === (pos === "bottom") ? 
    <FaLongArrowAltUp title={cTitle} style={{height: "20px"}} /> : 
    <FaLongArrowAltDown title={cTitle} style={{height: "20px"}} />;
  const horizontalIcon = isCollapsed === (pos === "right") ? 
    <FaLongArrowAltLeft title={cTitle} /> : 
    <FaLongArrowAltRight title={cTitle} />;

  let collapseOrientation = pos;
  if(isCollapsed) {
    collapseOrientation = isVertical ? "right" : "bottom"; // when collapsed, show buttons to the right if vertical and to the bottom if horizontal
  }

  return (
    <div 
        className={`panel-control-button collapse-handle collapse-handle-${collapseOrientation} highlight-inverse`} 
        onClick={() => {
          if(!isCollapsed) {
            panel.collapse();
          }
          else {
            panel.expand();
            if(panel.getSize() <= panelMinSize + 1) {
              // it gets stuck sometimes
              panel.resize(panelDefaultSize);
            }
          }
      }}>
        {isVertical ? verticalIcon : horizontalIcon}
      </div>
  );
};

const ControlButtons = ({
  panelRef, panelMinSize, panelDefaultSize, 
  currentSpaceCockpitPosition, setSpaceCockpitPosition
}) => {

  const pos = getSCPos(currentSpaceCockpitPosition);
  const isVertical = isVerticalLayout(currentSpaceCockpitPosition);

  const panel = panelRef.current;
  const isCollapsed = panel?.isCollapsed();
  const direction = isVertical ? "vertical" : "horizontal";
  const orientation = isVertical !== isCollapsed ? "vertical" : "horizontal";

  const highlightClass = (isEnabled) => isEnabled ? "highlighted" : "highlight";

  let flexDir = isVertical !== isCollapsed ? "column" : "row";
  
  const shift = (pos === "left" || pos === "top") && !isCollapsed;

  return (
    <div className={`pcc-container pcc-container-orient-${orientation}`}>
      <div className={`panel-control-container pcc-${direction} pcc-orient-${orientation}`} style={{flexDirection:flexDir}}>
        {!shift && <CollapseButton 
          panelRef={panelRef} 
          panelMinSize={panelMinSize} 
          panelDefaultSize={panelDefaultSize}
          currentSpaceCockpitPosition={currentSpaceCockpitPosition}
        />}

        <div className={`panel-control-button ${highlightClass(pos === "left")} ${shift ? "pcb-shifted" : ""}`} 
          onClick={() => setSpaceCockpitPosition("left")}>
          <BiSolidDockLeft title="Dock to left" />
        </div>

        <div className={`panel-control-button ${highlightClass(pos === "bottom")}`} 
          onClick={() => setSpaceCockpitPosition("bottom")}>
          <BiSolidDockBottom title="Dock to bottom" />
        </div>

        <div className={`panel-control-button ${highlightClass(pos === "right")}`} 
          onClick={() => setSpaceCockpitPosition("right")}>
          <BiSolidDockRight title="Dock to right" />
        </div>

        <div className={`panel-control-button ${highlightClass(pos === "top")}`} 
          onClick={() => setSpaceCockpitPosition("top")}>
          <BiSolidDockTop title="Dock to top" />
        </div>

        {shift && <CollapseButton 
          panelRef={panelRef} 
          panelMinSize={panelMinSize} 
          panelDefaultSize={panelDefaultSize}
          currentSpaceCockpitPosition={currentSpaceCockpitPosition} />}
      </div>
    </div>
  );
}

const SCFrame = ({scPanelRef, dragging, rerenderControl, setRerenderControl, 
    panelMinSize, panelDefaultSize, id, 
    currentSpaceCockpitPosition, setSpaceCockpitPosition, sats, focus, time}) => {

  const pos = getSCPos(currentSpaceCockpitPosition);
  const isVertical = isVerticalLayout(currentSpaceCockpitPosition);

  useEffect(() => {
    setRerenderControl((r) => r+1);
  }, [setRerenderControl, currentSpaceCockpitPosition?.position]);

  return (
    // key set to link forces remount for different dashboard
    <div style={{
      height: '100%',
      width: '100%',
      padding: "0px",
      display: "flex",
      flexDirection: isVertical ? "row" : "column"
    }}>

      <ControlButtons panelRef={scPanelRef} 
        panelMinSize={panelMinSize} panelDefaultSize={panelDefaultSize}
        currentSpaceCockpitPosition={currentSpaceCockpitPosition} setSpaceCockpitPosition={setSpaceCockpitPosition}
        />

      <div style={{
        height: '100%',
        width: '100%',
        padding: "16px",
        paddingTop: pos === "bottom" ? "16px" : "0px",
        paddingLeft: isVertical ? "0px" : "16px",
      }}>
        <SpaceCockpitFrame 
        key={id} 
        visible={!dragging && !scPanelRef.current?.isCollapsed()} 
        rerenderControl={rerenderControl} 
        sats={sats} 
        focus={focus} 
        time={time} 
        />
      </div>
    </div>
  );
}

const SCPanel = ({dragging, scSpacing, minSize, id, 
  currentSpaceCockpitPosition, setSpaceCockpitPosition, sats, focus, time }) => {

  const scPanelRef = useRef(null);
  const [rerenderControl, setRerenderControl] = useState(0);

  return (
    <Panel collapsible defaultSize={scSpacing} collapsedSize={minSize} minSize={minSize} data-testid="dash-sc-panel"
      ref={scPanelRef}
      onResize={() => setRerenderControl(rerenderControl+1)}>
      <SCFrame {...{scPanelRef, dragging, rerenderControl, setRerenderControl}} 
        panelMinSize={minSize}
        panelDefaultSize={scSpacing}
        id={id}
        currentSpaceCockpitPosition={currentSpaceCockpitPosition}
        setSpaceCockpitPosition={setSpaceCockpitPosition}
        sats={sats}
        focus={focus}
        time={time}
      />
    </Panel>
  );
};

const getContentPanelStyle = (sidePadding, pos) => ({
  height: "100%",
  width: "100%",
  paddingTop: sidePadding ? "16px" : undefined,
  paddingLeft: sidePadding ? "16px" : undefined,
  paddingRight: (sidePadding || pos === "right") ? "16px" : undefined,
  paddingBottom: (sidePadding || pos === "bottom") ? "16px" : undefined,
});

const SpaceCockpitDockContainer = ({
  id, currentSpaceCockpitPosition, setSpaceCockpitPosition, 
  sats, focus, time, 
  sidePadding=true,
  fullWidth=true,
  fullHeight=true, // set this to false if you are using dynamic height in the parent (display: flex and align-items: stretch)
  children
}) => {

  useEffect(() => {
    // Clean up any leftover canvas as a safety measure
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.remove();
    }
  }, []);

  const [dragging, setDragging] = useState(false);
  
  const pos = getSCPos(currentSpaceCockpitPosition);
  const isVertical = isVerticalLayout(currentSpaceCockpitPosition);

  const scSpacing = currentSpaceCockpitPosition?.size * 100;
  const spacefanaSpacing = 100 - scSpacing;

  // minimum fraction needed to still show collapse/expand button
  const minSize = (30+16*2+4) / (isVertical ? (window.innerHeight-56-12) : window.innerWidth) * 100;

  const contentPanel = (
    <Panel defaultSize={spacefanaSpacing} minSize={10} data-testid="dash-spacefana-panel" 
      >
      <div style={getContentPanelStyle(sidePadding, pos)}>
        {children}
      </div>
    </Panel>
  );

  const scPanelProps = {dragging, scSpacing, minSize, id, currentSpaceCockpitPosition, setSpaceCockpitPosition, sats, focus, time};

  const scFirst = pos === "left" || pos === "top";
  const direction = isVertical ? "vertical" : "horizontal";

  return (  
    <>
      <div style={{
        width: fullWidth ? "100%" : undefined,
        height: fullHeight ? "100%" : undefined
      }}>
        {currentSpaceCockpitPosition ? (
            <PanelGroup key={id} autoSaveId={`resize-dashboard-${id}-${pos}`} 
              direction={direction}
              data-testid="dash-panel-group"
              >
              {scFirst ? <SCPanel {...scPanelProps} /> : contentPanel}
              
              <PanelResizeHandle className={`resize-handle resize-handle-${direction}`} 
                  onDragging={(isDragging) => setDragging(isDragging)} />
              
              {scFirst ? contentPanel : <SCPanel {...scPanelProps} />}
            </PanelGroup>
          ) : children}
      </div>
    </>
  );
};

export default SpaceCockpitDockContainer;
